import styled from "styled-components";
import { Typography, OutlinedInput } from "@material-ui/core";

import { GridFlex } from "../../components/Styles/Grid";

const StyledTypography = styled(Typography)<{ align?: "left" | "right" | "center" }>`
  max-width: 100%;
  /* font-family: PingFang SC; */
  color: #ffffff;
  text-align: ${({ align }) => `${align || "inherit"}`};
`;

export const Title = styled(StyledTypography)<{
  fs?: number;
  align?: "center" | "left" | "center";
}>`
  max-width: 100%;
  font-size: ${({ fs }) => `${fs || 100}px`};
  font-weight: bold;
  line-height: 120%;
  text-align: ${({ align }) => `${align || "inherit"}`};
  overflow: hidden;
`;

export const SubTitle = styled(StyledTypography)<{ fs?: number }>`
  max-width: 100%;
  font-size: ${({ fs }) => `${fs || 40}px`};
  font-weight: 400;
  line-height: 40px;
  overflow: hidden;
`;

export const StyledGridFlex = styled(GridFlex)<{
  align?: "flex-start" | "flex-end" | "center";
  pt?: number;
  fs?: number;
}>`
  flex-direction: column;
  align-items: ${({ align }) => align || "flex-start"};
  padding-top: ${({ pt }) => `${pt || 80}px`};
  font-size: ${({ fs }) => `${fs || 24}px`};
`;

export const StyledOutlinedInput = styled(OutlinedInput)<{
  top?: number;
  height?: number;
  fs?: number;
  right?: number;
}>`
  height: ${({ height }) => `${height || 48}px`};
  margin-top: ${({ top }) => `${top || 0}px`};
  font-size: ${({ fs }) => `${fs || 16}px`};
  color: #ffffff;
  background: #27282b;
  margin-right: ${({ right }) => `${right || 0}px`};
  /* border: 2px solid #d4d4d4; */
`;
