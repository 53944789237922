import { Link } from "react-router-dom";

export const ResponseLink = ({
  isLink,
  children,
  to,
}: {
  isLink: boolean;
  children: JSX.Element;
  to: string;
}) => {
  if (isLink) {
    return <Link to={to}>{children}</Link>;
  }
  return <>{children}</>;
};

export const ExternalLink = ({
  url,
  isLink,
  children,
}: {
  url?: string | undefined;
  isLink?: boolean | undefined;
  children: JSX.Element;
}) => {
  if (url && isLink) {
    return (
      <Link to={{ pathname: url }} target="_blank">
        {children}
      </Link>
    );
  }
  return <>{children}</>;
};

export default ResponseLink;
