import { Video } from "../models/User";

export const isEmailAddress = (email: string | null | undefined) => {
  if (!email) {
    return false;
  }
  return /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(email);
};

export const getErrorPromise = (err: { code?: number; message?: string }) => {
  return new Promise((_resolve, reject) => {
    reject(err);
  });
};

export const getSignMessageString = (videoId: string, userId: string, channelId: string) => {
  return `${videoId}-${userId}-${channelId}`;
};

export const generateVideData = (video: Video) => {
  return {
    videoId: video.id,
    channelId: video.channel?.id,
    channelTitle: video?.snippet?.channelTitle,
    channelAvatar: video?.channel?.snippet?.thumbnails?.default?.url,
    subscriberCount: video.channel?.statistics?.subscriberCount,
    description: video?.snippet?.description,
    videoThumbnail: video?.snippet?.thumbnails?.medium?.url,
    videoTitle: video?.snippet?.title,
    viewCount: video.statistics?.viewCount,
    publishedAt: video.snippet?.publishedAt,
  };
};
