import { useMemo } from "react";
import QRcode from "qrcode";

export const useQRcode = (text: string | undefined): Promise<string | null> => {
  return useMemo(() => {
    if (!text) {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
    return QRcode.toDataURL(text);
  }, [text]);
};

export const useKanamitPageQRcode = (page: string | undefined) => {
  return useQRcode(`https://vv.kanamit.io/video/${page}`);
};
