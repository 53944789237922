import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const GridBase = styled(Grid)``;

export const GridFlex = styled(Grid)<{
  top?: number;
  left?: number;
  padding?: string;
}>`
  display: flex;
  padding: ${({ padding }) => `${padding || "auto"}`};
  margin-top: ${(props) => `${props.top || 0}px`};
  margin-left: ${({ left }) => `${left || 0}px`};
`;
