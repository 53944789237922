export const NORMAL_SCREEN_PADDING_LEFT = 15;
export const SMALL_SCREEN_PADDING_LEFT = 15;

export const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const NETWORK_URL = process.env.REACT_APP_NETWORK_URL;

export const CORE_CONTRACT_ADDRESS = process.env.REACT_APP_CORE_CONTRACT_ADDRESS;
export const TRADE_CONTRACT_ADDRESS = process.env.REACT_APP_TRADE_CONTRACT_ADDRESS;
export const KANA_SHOP_ADDRESS = process.env.REACT_APP_KANA_SHOP_ADDRESS;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const USER_INFO = "USER_INFO";
export const USER_TOKEN = "USER_TOKEN";
export const USER_VIDEOS = "USER_VIDEOS";
export const ACCESS_TOKEN = "ACCESS_TOKEN";

const SCOPES = [
  "https://www.googleapis.com/auth/youtube.force-ssl",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/youtube.readonly",
];

export const GOOGLE_SCOPE = SCOPES.join(" ");
export const GOOGLE_DISCOVERY_URL = "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest";
