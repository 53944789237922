import { createMuiTheme } from "@material-ui/core/styles";
import { darken, lighten } from "polished";

const SECONDARY = "#ff0102";
const theme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        overline: "span",
        body2: "span",
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    secondary: {
      main: SECONDARY,
      light: lighten(0.6, SECONDARY),
      dark: darken(0.05, SECONDARY),
    },
    text: {
      primary: "#000000",
      secondary: "#585758",
    },
  },
});

export default theme;
