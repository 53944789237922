import Container from "@material-ui/core/Container";

import Header from "../../Header";
import { GridFlex } from "../../../../components/Styles/Grid";
import { StyledContainer, Link, TextBox, Title, SubTitle, Text, WarningText } from "../Styles";

export const Terms = () => {
  return (
    <Container disableGutters maxWidth={false}>
      <Header showMenu background="#1C1D1F" />
      <StyledContainer disableGutters maxWidth={false}>
        <Container disableGutters>
          <GridFlex container direction="column">
            <TextBox>
              <Title>Kanamit, Inc. Terms of Use</Title>
            </TextBox>
            <TextBox>
              <Text>Thank you for using Kanamit!</Text>
            </TextBox>
            <TextBox>
              <WarningText>
                PLEASE READ THESE TERMS OF USE CAREFULLY. BEFORE YOU USING OUR APPLICATION OR
                SERVICE, YOU NEED TO AGREE TO THESE TERMS OF USE.
              </WarningText>
              <Text>
                These terms govern your use of our application. by using the application or any part
                of it, or by clicking “i accept” below or indicating your acceptance in an adjoining
                box, you are confirming that you understand and agree to be bound by all of these
                terms.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                We may update these Terms of Use from time to time, and may condition your continued
                use of our Site on your agreeing to those revised terms. if you are accepting these
                terms on behalf of a company or other legal entity, you represent that you have the
                legal authority to accept these terms on that entity’s behalf, in which case “you”
                will mean that entity. if you do not have such authority, or if you do not accept
                all of these terms, then we are unwilling to make the app available to you. if you
                do not agree to these terms, you may not access or use the app.
              </Text>
            </TextBox>
            <TextBox>
              Kanamit, Inc. is an application that allows you to mint your Youtube videos to NFTs,
              so that you can trade your youtube videos through https://kanamit.io, vv.kanamit.io
              (collectively, the Site). The terms “Kanamit”, “Site”, “us” or “we” refers to Kanamit,
              Inc., The term “you” refers to you, as a User of our Site.
            </TextBox>
            <TextBox>
              <SubTitle>Accounts</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                Account Creation. For you to use the service of the Site, you have to start an
                account and provide certain information about yourself. You warrant that: (a) all
                required registration information you submit is truthful, up-to-date and accurate;
                (b) you will maintain the accuracy of such information. If you want to delete your
                account, please contact kanamitnft@gmail.com. (c)you have fully understood and
                agreed to these Terms of Use. The information we collect in connection with creating
                your account, all details about how we use the information were stated in our
                <Link href="https://www.kanamit.io/user/pp" target="_blank">
                  &nbsp; Privacy Policy
                </Link>
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Use your account: In order to create an account on our site, we ask you to provide
                accurate information.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Ethereum address: Transactions that take place on the Site are managed and confirmed
                via the Ethereum blockchain. You understand that your Ethereum public address will
                be stored by us so that we may track the transactions you engage in on the Site. You
                are solely responsible for the security of your Ethereum wallet, Coinbase wallet,
                MetaMask account and other accounts, browsers or extensions you use to initiate
                transactions on the Site
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Account Responsibilities. You are responsible for maintaining the confidentiality of
                your Account login information and are fully responsible for all activities that
                occur under your Account. You approve to immediately notify the Kanamit of any
                unauthorized use, or suspected unauthorized use of your Account. Kanamit cannot and
                will not be liable for any loss or damage arising from your failure to comply with
                the above requirements.
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>Access to the Site</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                Subject to these Terms. We grant you permission to use the Site at your own risk.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Certain Restrictions. The rights approved to you in these Terms are subject to the
                following restrictions: (a) you shall not sell, rent, lease, transfer, assign,
                distribute, host, or otherwise commercially exploit the Site; (b) you shall not
                change, make derivative works of, disassemble, reverse compile or reverse engineer
                any part of the Site; (c) you shall not access the Site in order to build a similar
                or competitive website; and (d) except as expressly stated herein, no part of the
                Site may be copied, reproduced, distributed, republished, downloaded, displayed,
                posted or transmitted in any form or by any means unless otherwise indicated, any
                future release, update, or other addition to functionality of the Site shall be
                subject to these Terms. All copyright and other proprietary notices on the Site must
                be retained on all copies thereof.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Kanamit reserves the right to change, suspend, or cease the Site with or without
                notice to you. You approved that Kanamit will not be held liable to you or any
                third-party for any change, interruption, or termination of the Site or any part.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                No Support or Maintenance. You agree that Kanamit will have no obligation to provide
                you with any support in connection with the Site.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Excluding any User Content that you may provide, you are aware that all the
                intellectual property rights, including copyrights, patents, trademarks, and trade
                secrets, in the Site and its content are owned by Kanamit or Kanamit's suppliers.
                Note that these Terms and access to the Site do not give you any rights, title or
                interest in or to any intellectual property rights, except for the limited access
                rights expressed in Section 2.1. Kanamit and its suppliers reserve all rights not
                granted in these Terms.
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>User Content</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                User Content. “User Content” means any and all information and content that a user
                submits to the Site. You are exclusively responsible for your User Content. You bear
                all risks associated with use of your User Content. You hereby certify that your
                User Content does not violate our Acceptable Use Policy. You may not represent or
                imply to others that your User Content is in any way provided, sponsored or endorsed
                by Kanamit. Because you are solely responsible for your User Content, you may expose
                yourself to liability. Kanamit is not obliged to backup any User Content that you
                post; also, your User Content may be deleted at any time without prior notice to
                you. You are solely responsible for making your own backup copies of your User
                Content if you desire.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                You hereby grant to Kanamit an irreversible, nonexclusive, royalty-free and fully
                paid, worldwide license to reproduce, distribute, publicly display and perform,
                prepare derivative works of, incorporate into other works, and otherwise use and
                exploit your User Content, and to grant sublicenses of the foregoing rights, solely
                for the purposes of including your User Content in the Site. You hereby irreversibly
                waive any claims and assertions of moral rights or attribution with respect to your
                User Content.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Acceptable Use Policy. The following terms constitute our “Acceptable Use Policy”:
                You agree not to use the Site to collect, upload, transmit, display, or distribute
                any User Content (i) that violates any third-party right or any intellectual
                property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious,
                threatening, harmful, invasive of another's privacy, vulgar, defamatory, false,
                intentionally misleading, trade libelous, pornographic, obscene, patently offensive,
                promotes racism, bigotry, hatred, or physical harm of any kind against any group or
                individual; (iii) that is harmful to minors in any way; or (iv) that is in violation
                of any law, regulation, or obligations or restrictions imposed by any third party.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                In addition, you agree not to: (i) upload, transmit, or distribute to or through the
                Site any software intended to damage or alter a computer system or data; (ii) send
                through the Site unsolicited or unauthorized advertising, promotional materials,
                junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or
                unsolicited messages; (iii) use the Site to harvest, collect, gather or assemble
                information or data regarding other users without their consent; (iv) interfere
                with, disrupt, or create an undue burden on servers or networks connected to the
                Site, or violate the regulations, policies or procedures of such networks; (v)
                attempt to gain unauthorized access to the Site, whether through password mining or
                any other means; (vi) harass or interfere with any other user's use and enjoyment of
                the Site; or (vi) use software or automated agents or scripts to produce multiple
                accounts on the Site, or to generate automated searches, requests, or queries to the
                Site.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                We reserve the right to review any User Content, and to investigate and/or take
                appropriate action against you in our sole discretion if you violate the Acceptable
                Use Policy or any other provision of these Terms or otherwise create liability for
                us or any other person. Such action may include removing or modifying your User
                Content, terminating your Account in accordance with Section 8, and/or reporting you
                to law enforcement authorities.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                If you provide Kanamit with any feedback or suggestions regarding the Site, you
                hereby assign to Kanamit all rights in such Feedback and agree that Kanamit shall
                have the right to use and fully exploit such Feedback and related information in any
                manner it believes appropriate. Kanamit will treat any Feedback you provide to
                Kanamit as non-confidential and non-proprietary.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                You agree to indemnify and hold Kanamit and its officers, employees, and agents
                harmless, including costs and attorneys' fees, from any claim or demand made by any
                third-party due to or arising out of (a) your use of the Site, (b) your violation of
                these Terms, (c) your violation of applicable laws or regulations or (d) your User
                Content. Kanamit reserves the right to assume the exclusive defense and control of
                any matter for which you are required to indemnify us, and you agree to cooperate
                with our defense of these claims. You agree not to settle any matter without the
                prior written consent of Kanamit. Kanamit will use reasonable efforts to notify you
                of any such claim, action or proceeding upon becoming aware of it.
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>Third-Party Links & Ads; Other Users</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                Third-Party Links & Ads. The Site may contain links to third-party websites and
                services, and/or display advertisements for third-parties. Such Third-Party Links &
                Ads are not under the control of Kanamit, and Kanamit is not responsible for any
                Third-Party Links & Ads. Kanamit provides access to these Third-Party Links & Ads
                only as a convenience to you, and does not review, approve, monitor, endorse,
                warrant, or make any representations with respect to Third-Party Links & Ads. You
                use all Third-Party Links & Ads at your own risk, and should apply a suitable level
                of caution and discretion in doing so. When you click on any of the Third-Party
                Links & Ads, the applicable third party's terms and policies apply, including the
                third party's privacy and data gathering practices.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Other Users. Each Site user is solely responsible for any and all of its own User
                Content. Because we do not control User Content, you acknowledge and agree that we
                are not responsible for any User Content, whether provided by you or by others. You
                agree that Kanamit will not be responsible for any loss or damage incurred as the
                result of any such interactions. If there is a dispute between you and any Site
                user, we are under no obligation to become involved.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                You hereby release and forever discharge the Kanamit and our officers, employees,
                agents, successors, and assigns from, and hereby waive and relinquish, each and
                every past, present and future dispute, claim, controversy, demand, right,
                obligation, liability, action and cause of action of every kind and nature, that has
                arisen or arises directly or indirectly out of, or that relates directly or
                indirectly to, the Site. If you are a California resident, you hereby waive
                California civil code section 1542 in connection with the foregoing, which states:
                “a general release does not extend to claims which the creditor does not know or
                suspect to exist in his or her favor at the time of executing the release, which if
                known by him or her must have materially affected his or her settlement with the
                debtor.”
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>Payment, gas fees, and taxes</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                Financial Transactions on Site. Any payments or transactions that you engage in via
                the Site will be conducted solely through the Ethereum Network. We don’t control
                these payments or transactions, nor do we have the ability to reverse any payments
                or transactions. We have no liability to you or to any third party for any claims or
                damages that may arise as a result of any payments or transactions that you engage
                in via the Site, or any other payment or transactions that you conduct via the
                Ethereum Network.
              </Text>
            </TextBox>
            <TextBox>
              <WarningText>
                We do not provide refunds for any purchases that you might make on or through the
                Site– whether for NFT, Video, or anything else.
              </WarningText>
            </TextBox>
            <TextBox>
              <Text>
                Gas Fees. Every transaction on the Ethereum Network requires the payment of a
                transaction fee (each, a “Gas Fee”). The Gas Fees fund the network of computers that
                run the decentralized Ethereum Network. This means that you will need to pay a Gas
                Fee for each transaction that you instigate via the Site. Except as otherwise
                expressly set forth in these Terms, you will be solely responsible to pay any Gas
                Fee for any transaction that you instigate via the Site.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Responsibility for Taxes. You will be solely responsible to pay any and all sales,
                use, value-added and other taxes, duties, and assessments (except taxes on our net
                income) now or hereafter claimed or imposed by any governmental authority
                (collectively, the “Taxes”) associated with your use of the App. Except for income
                taxes levied on us, you: (a) will pay or reimburse us for all national, federal,
                state, local or other taxes and assessments of any jurisdiction, including value
                added taxes and taxes as required by international tax treaties, customs or other
                import or export taxes, and amounts levied in lieu thereof based on charges set,
                services performed or payments made hereunder, as are now or hereafter may be
                imposed under the authority of any national, state, local or any other taxing
                jurisdiction; and (b) will not be entitled to deduct the amount of any such taxes,
                duties or assessments from payments (including Gas Fees) made to us pursuant to
                these Terms. To allow us to determine our tax obligations, unless you otherwise
                notify us in writing, you confirm that you are not a resident in Canada nor are you
                registered for Goods and services tax / Harmonized sales tax (GST / HST) or
                Provincial sales taxes (PST) in Canada, and will inform us if your status changes in
                the future.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Value and Volatility. The prices of collectible blockchain assets are extremely
                volatile and subjective and collectible blockchain assets have no inherent or
                intrinsic value. Fluctuations in the price of other digital assets could materially
                and adversely affect the value of your Videos, which may also be subject to
                significant price volatility. Each Video has no inherent or intrinsic value. We
                cannot guarantee that any Video purchased will retain their original value, as the
                value of collectibles is inherently subjective and factors occurring outside of the
                Kanamit ecosystem may materially impact the value and desirability of any particular
                Moment.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Tax Calculations. You are solely responsible for determining what, if any, taxes
                apply to your Video-related transactions. We are not responsible for determining the
                taxes that apply to your transactions on the Site.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Use of Blockchain. The Site does not store, send, or receive Video. This is because
                Video exists only by virtue of the ownership record maintained on the Site’s
                supporting blockchain in the Ethereum network. Any transfer of Video occurs within
                the supporting blockchain in the Ethereum network, and not on the Site.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Inherent Risks with Internet Currency. There are risks associated with using an
                Internet-based currency, including, but not limited to, the risk of hardware,
                software and Internet connections, the risk of malicious software introduction, and
                the risk that third parties may obtain unauthorized access to information stored
                within your electronic wallet. You accept and acknowledge that we will not be
                responsible for any communication failures, disruptions, errors, distortions or
                delays you may experience when using the Ethereum Network, however caused.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Regulatory Uncertainty. The regulatory regime governing blockchain technologies,
                cryptocurrencies and tokens is uncertain, and new regulations or policies may
                materially adversely affect the development of the Kanamit ecosystem, and therefore
                the potential utility or value of your Moments.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Software Risks. Upgrades to the Ethereum Network, a hard fork in the Ethereum
                Network, or a change in how transactions are confirmed on the Ethereum Network may
                have unintended, adverse effects on all blockchains using the Ethereum Network’s NFT
                standard, including the Kanamit ecosystem.{" "}
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>Disclaimers</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                The site is provided on an “as-is” and “as available” basis, and Kanamit and our
                suppliers expressly disclaim any and all warranties and conditions of any kind,
                whether express, implied, or statutory, including all warranties or conditions of
                merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy,
                or non-infringement. We and our suppliers make not guarantee that the site will meet
                your requirements, will be available on an uninterrupted, timely, secure, or
                error-free basis, or will be accurate, reliable, free of viruses or other harmful
                code, complete, legal, or safe. If applicable law requires any warranties with
                respect to the site, all such warranties are limited in duration to ninety (90) days
                from the date of first use.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Some jurisdictions do not allow the exclusion of implied warranties, so the above
                exclusion may not apply to you. Some jurisdictions do not allow limitations on how
                long an implied warranty lasts, so the above limitation may not apply to you.
              </Text>
            </TextBox>

            <TextBox>
              <SubTitle>Limitation on Liability</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                To the maximum extent permitted by law, in no event shall Kanamit or our suppliers
                be liable to you or any third-party for any lost profits, lost data, costs of
                procurement of substitute products, or any indirect, consequential, exemplary,
                incidental, special or punitive damages arising from or relating to these terms or
                your use of, or incapability to use the site even if Kanamit has been advised of the
                possibility of such damages. Access to and use of the site is at your own discretion
                and risk, and you will be solely responsible for any damage to your device or
                computer system, or loss of data resulting therefrom.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                To the maximum extent permitted by law, notwithstanding anything to the contrary
                contained herein, our liability to you for any damages arising from or related to
                this agreement, will at all times be limited to a maximum of fifty U.S. dollars
                (u.s. $50). The existence of more than one claim will not enlarge this limit. You
                agree that our suppliers will have no liability of any kind arising from or relating
                to this agreement.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Some jurisdictions do not allow the limitation or exclusion of liability for
                incidental or consequential damages, so the above limitation or exclusion may not
                apply to you.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Term and Termination. Subject to this Section, these Terms will remain in full force
                and effect while you use the Site. We may suspend or terminate your rights to use
                the Site at any time for any reason at our sole discretion, including for any use of
                the Site in violation of these Terms. Upon termination of your rights under these
                Terms, your Account and right to access and use the Site will terminate immediately.
                You understand that any termination of your Account may involve deletion of your
                User Content associated with your Account from our live databases. Kanamit will not
                have any liability whatsoever to you for any termination of your rights under these
                Terms.
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>Copyright Policy.</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                Kanamit respects the intellectual property of others and asks that users of our Site
                do the same. In connection with our Site, we have adopted and implemented a policy
                respecting copyright law that provides for the removal of any infringing materials
                and for the termination of users of our online Site who are repeated infringers of
                intellectual property rights, including copyrights. If you believe that one of our
                users is, through the use of our Site, unlawfully infringing the copyright(s) in a
                work, and wish to have the allegedly infringing material removed, the following
                information in the form of a written notification (pursuant to 17 U.S.C. § 512(c))
                must be provided to our designated Copyright Agent:
              </Text>
            </TextBox>
            <TextBox>
              <Text>your physical or electronic signature;</Text>
            </TextBox>
            <TextBox>
              <Text>
                identification of the copyrighted work(s) that you claim to have been infringed;
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                identification of the material on our services that you claim is infringing and that
                you request us to remove;
              </Text>
            </TextBox>
            <TextBox>
              <Text>sufficient information to permit us to locate such material;</Text>
            </TextBox>
            <TextBox>
              <Text>your address, telephone number, and e-mail address;</Text>
            </TextBox>
            <TextBox>
              <Text>
                a statement that you have a good faith belief that use of the objectionable material
                is not authorized by the copyright owner, its agent, or under the law; and
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                a statement that the information in the notification is accurate, and under penalty
                of perjury, that you are either the owner of the copyright that has allegedly been
                infringed or that you are authorized to act on behalf of the copyright owner.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material
                fact in a written notification automatically subjects the complaining party to
                liability for any damages, costs and attorney's fees incurred by us in connection
                with the written notification and allegation of copyright infringement.
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>General</SubTitle>
            </TextBox>
            <TextBox>
              <Text>
                These Terms are subject to occasional revision, and if we make any substantial
                changes, we may notify you by sending you an e-mail to the last e-mail address you
                provided to us and/or by prominently posting notice of the changes on our Site. You
                are responsible for providing us with your most current e-mail address. In the event
                that the last e-mail address that you have provided us is not valid, our dispatch of
                the e-mail containing such notice will nonetheless constitute effective notice of
                the changes described in the notice. Any changes to these Terms will be effective
                upon the earliest of thirty (30) calendar days following our dispatch of an e-mail
                notice to you or thirty (30) calendar days following our posting of notice of the
                changes on our Site. These changes will be effective immediately for new users of
                our Site. Continued use of our Site following notice of such changes shall indicate
                your acknowledgement of such changes and agreement to be bound by the terms and
                conditions of such changes. Dispute Resolution. Please read this Arbitration
                Agreement carefully. It is part of your contract with Kanamit and affects your
                rights. It contains procedures for
              </Text>
              <TextBox>
                <WarningText>MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</WarningText>
              </TextBox>
            </TextBox>
            <TextBox>
              <Text>
                Entire Terms. These Terms constitute the entire agreement between you and us
                regarding the use of the Site. Our failure to exercise or enforce any right or
                provision of these Terms shall not operate as a waiver of such right or provision.
                The section titles in these Terms are for convenience only and have no legal or
                contractual effect. The word “including” means “including without limitation”. If
                any provision of these Terms is held to be invalid or unenforceable, the other
                provisions of these Terms will be unimpaired and the invalid or unenforceable
                provision will be deemed modified so that it is valid and enforceable to the maximum
                extent permitted by law. Your relationship to Kanamit is that of an independent
                contractor, and neither party is an agent or partner of the other. These Terms, and
                your rights and obligations herein, may not be assigned, subcontracted, delegated,
                or otherwise transferred by you without Kanamit's prior written consent, and any
                attempted assignment, subcontract, delegation, or transfer in violation of the
                foregoing will be null and void. Kanamit may freely assign these Terms. The terms
                and conditions set forth in these Terms shall be binding upon assignees.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Applicability of Arbitration Agreement. All claims and disputes in connection with
                the Terms or the use of any product or service provided by the Kanamit that cannot
                be resolved informally or in small claims court shall be resolved by binding
                arbitration on an individual basis under the terms of this Arbitration Agreement.
                Unless otherwise agreed to, all arbitration proceedings shall be held in English.
                This Arbitration Agreement applies to you and the Kanamit, and to any subsidiaries,
                affiliates, agents, employees, predecessors in interest, successors, and assigns, as
                well as all authorized or unauthorized users or beneficiaries of services or goods
                provided under the Terms.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Notice Requirement and Informal Dispute Resolution. Before either party may seek
                arbitration, the party must first send to the other party a written Notice of
                Dispute describing the nature and basis of the claim or dispute, and the requested
                relief. A Notice to the Kanamit should be sent to kanamitnft@gmail.com . After the
                Notice is received, you and the Kanamit may attempt to resolve the claim or dispute
                informally. If you and the Kanamit do not resolve the claim or dispute within thirty
                (30) days after the Notice is received, either party may begin an arbitration
                proceeding. The amount of any settlement offer made by any party may not be
                disclosed to the arbitrator until after the arbitrator has determined the amount of
                the award to which either party is entitled.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Arbitration Rules. Arbitration shall be initiated through the American Arbitration
                Association, an established alternative dispute resolution provider that offers
                arbitration as set forth in this section. If AAA is not available to arbitrate, the
                parties shall agree to select an alternative ADR Provider. The rules of the ADR
                Provider shall govern all aspects of the arbitration except to the extent such rules
                are in conflict with the Terms. The AAA Consumer Arbitration Rules governing the
                arbitration are available online at adr.org or by calling the AAA at 1-800-778-7879.
                The arbitration shall be conducted by a single, neutral arbitrator. Any claims or
                disputes where the total amount of the award sought is less than Ten Thousand U.S.
                Dollars (US $10,000.00) may be resolved through binding non-appearance-based
                arbitration, at the option of the party seeking relief. For claims or disputes where
                the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or
                more, the right to a hearing will be determined by the Arbitration Rules. Any
                hearing will be held in a location within 100 miles of your residence, unless you
                reside outside of the United States, and unless the parties agree otherwise. If you
                reside outside of the U.S., the arbitrator shall give the parties reasonable notice
                of the date, time and place of any oral hearings. Any judgment on the award rendered
                by the arbitrator may be entered in any court of competent jurisdiction. If the
                arbitrator grants you an award that is greater than the last settlement offer that
                the Kanamit made to you prior to the initiation of arbitration, the Kanamit will pay
                you the greater of the award or $2,500.00. Each party shall bear its own costs and
                disbursements arising out of the arbitration and shall pay an equal share of the
                fees and costs of the ADR Provider.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Additional Rules for Non-Appearance Based Arbitration. If non-appearance based
                arbitration is elected, the arbitration shall be conducted by telephone, online
                and/or based solely on written submissions; the specific manner shall be chosen by
                the party initiating the arbitration. The arbitration shall not involve any personal
                appearance by the parties or witnesses unless otherwise agreed by the parties.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Time Limits. If you or the Kanamit pursues arbitration, the arbitration action must
                be initiated and/or demanded within the statute of limitations and within any
                deadline imposed under the AAA Rules for the pertinent claim.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Authority of Arbitrator. If arbitration is initiated, the arbitrator will decide the
                rights and liabilities of you and the Kanamit, and the dispute will not be
                consolidated with any other matters or joined with any other cases or parties. The
                arbitrator shall have the authority to grant motions dispositive of all or part of
                any claim. The arbitrator shall have the authority to award monetary damages, and to
                grant any non-monetary remedy or relief available to an individual under applicable
                law, the AAA Rules, and the Terms. The arbitrator shall issue a written award and
                statement of decision describing the essential findings and conclusions on which the
                award is based. The arbitrator has the same authority to award relief on an
                individual basis that a judge in a court of law would have. The award of the
                arbitrator is final and binding upon you and the Kanamit.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY
                RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead
                electing that all claims and disputes shall be resolved by arbitration under this
                Arbitration Agreement. Arbitration procedures are typically more limited, more
                efficient and less expensive than rules applicable in a court and are subject to
                very limited review by a court. In the event any litigation should arise between you
                and the Kanamit in any state or federal court in a suit to vacate or enforce an
                arbitration award or otherwise, YOU AND THE KANAMIT WAIVE ALL RIGHTS TO A JURY
                TRIAL, instead electing that the dispute be resolved by a judge.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Waiver of Class or Consolidated Actions. All claims and disputes within the scope of
                this arbitration agreement must be arbitrated or litigated on an individual basis
                and not on a class basis, and claims of more than one customer or user cannot be
                arbitrated or litigated jointly or consolidated with those of any other customer or
                user.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Confidentiality. All aspects of the arbitration proceeding shall be strictly
                confidential. The parties agree to maintain confidentiality unless otherwise
                required by law. This paragraph shall not prevent a party from submitting to a court
                of law any information necessary to enforce this Agreement, to enforce an
                arbitration award, or to seek injunctive or equitable relief.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Severability. If any part or parts of this Arbitration Agreement are found under the
                law to be invalid or unenforceable by a court of competent jurisdiction, then such
                specific part or parts shall be of no force and effect and shall be severed and the
                remainder of the Agreement shall continue in full force and effect.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Right to Waive. Any or all of the rights and limitations set forth in this
                Arbitration Agreement may be waived by the party against whom the claim is asserted.
                Such waiver shall not waive or affect any other portion of this Arbitration
                Agreement.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Survival of Agreement. This Arbitration Agreement will survive the termination of
                your relationship with Kanamit.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Small Claims Court. Nonetheless the foregoing, either you or the Kanamit may bring
                an individual action in small claims court.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Emergency Equitable Relief. Anyhow the foregoing, either party may seek emergency
                equitable relief before a state or federal court in order to maintain the status quo
                pending arbitration. A request for interim measures shall not be deemed a waiver of
                any other rights or obligations under this Arbitration Agreement.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Claims Not Subject to Arbitration. Notwithstanding the foregoing, claims of
                defamation, violation of the Computer Fraud and Abuse Act, and infringement or
                misappropriation of the other party's patent, copyright, trademark or trade secrets
                shall not be subject to this Arbitration Agreement.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                In any circumstances where the foregoing Arbitration Agreement permits the parties
                to litigate in court, the parties hereby agree to submit to the personal
                jurisdiction of the courts located within Netherlands County, California, for such
                purposes.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                The Site may be subject to U.S. export control laws and may be subject to export or
                import regulations in other countries. You agree not to export, re-export, or
                transfer, directly or indirectly, any U.S. technical data acquired from Kanamit, or
                any products utilizing such data, in violation of the United States export laws or
                regulations.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Electronic Communications. The communications between you and Kanamit use electronic
                means, whether you use the Site or send us emails, or whether Kanamit posts notices
                on the Site or communicates with you via email. For contractual purposes, you (a)
                consent to receive communications from Kanamit in an electronic form; and (b) agree
                that all terms and conditions, agreements, notices, disclosures, and other
                communications that Kanamit provides to you electronically satisfy any legal
                obligation that such communications would satisfy if it were be in a hard copy
                writing.
              </Text>
            </TextBox>
            <TextBox>
              <Text>
                Copyright/Trademark Information. Copyright ©. All rights reserved. All trademarks,
                logos and service marks displayed on the Site are our property or the property of
                other third-parties. You are not permitted to use these Marks without our prior
                written consent or the consent of such third parties which may own the Marks.
              </Text>
            </TextBox>
            <TextBox>
              <SubTitle>Contact Information</SubTitle>
            </TextBox>
            <TextBox>
              <Text>Email: Kanamitnft@gmail.com</Text>
            </TextBox>
            <TextBox>
              <Text>
                Last Update. This Terms of Use document was last modified on 6/15/2021. Effective
                date: 6/15/2021
              </Text>
            </TextBox>
          </GridFlex>
        </Container>
      </StyledContainer>
    </Container>
  );
};
