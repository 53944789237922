import { IconButton, Typography } from "@material-ui/core";
import styled from "styled-components";

import { GridFlex } from "../../components/Styles/Grid";
import { AvatarLogo } from "../../components/Styles/Avatar";

import SloganBg from "../../images/voxels/bg01.png";

import ArrowDown from "../../images/svg/arrow_down.svg";
import { useSmallScreen } from "../../hooks/useSmallScreen";

const MainTitle = styled(Typography)<{ fs?: number }>`
  width: 100%;
  max-width: 100%;
  font-size: ${({ fs }) => `${fs || 100}px`};
  color: #ffffff;
  /* font-family: PingFang SC; */
  font-weight: bold;
  overflow: hidden;
`;

const Description = styled(Typography)<{ fs?: number }>`
  width: 100%;
  max-width: 700px;
  margin: 40px 0 70px 0;
  font-size: ${({ fs }) => `${fs || 28}px`};
  /* font-family: PingFang SC; */
  font-weight: 400;
  line-height: 40px;
  color: #ffffff;
  opacity: 1;
`;

const StyledGridFlex = styled(GridFlex)<{ pt?: number; pd?: number }>`
  flex-direction: column;
  position: relative;
  padding-top: ${({ pt }) => `${pt || 150}px`};
  padding-bottom: ${({ pd }) => `${pd || 300}px`};
  background-image: url(${SloganBg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
`;

const StyledIconButton = styled(IconButton)<{ small?: string }>`
  width: 55px;
  height: 55px;
  margin-top: ${({ small }) => `${small === "true" ? 40 : 0}px`};
`;

export const Slogan = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <StyledGridFlex item xs={12} pt={isSmallScreen ? 65 : 150} pd={isSmallScreen ? 200 : 300}>
      <MainTitle fs={isSmallScreen ? 32 : 48}>Kanamit NFT Protocols</MainTitle>
      <MainTitle fs={isSmallScreen ? 32 : 48}>Framework</MainTitle>
      <Description fs={22}>Make NFT Development Easy</Description>
      <StyledIconButton>
        <AvatarLogo width={55} height={55} variant="circle" src={ArrowDown} />
      </StyledIconButton>
    </StyledGridFlex>
  );
};
