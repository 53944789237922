import { useRef, MutableRefObject } from "react";
import styled from "styled-components";
// import { IconButton } from "@material-ui/core";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { BoxBase } from "../../components/Styles/Box";
import { GridFlex } from "../../components/Styles/Grid";
import { SecondaryButton } from "../../components/Styles/Button";
import { Title, SubTitle, StyledOutlinedInput } from "./Styles";

import BackgroundThree from "../../images/voxels/bg03.png";
import { useSmallScreen } from "../../hooks/useSmallScreen";
import { useAttentionDialog } from "../../hooks/Component/useDialog";
import { isEmailAddress } from "../../utils";
import { saveEmail } from "../../api/server/email";

const primaryTheme = createMuiTheme({ palette: { primary: { main: "#FFFFFF" } } });

const StyledGridFlex = styled(GridFlex)`
  /* background-repeat: no-repeat;
  background-image: url(${BackgroundThree});
  background-position: center top;
  background-size: contain; */
`;

const StyledButton = styled(SecondaryButton)<{ fs?: number }>`
  & span {
    font-size: ${({ fs }) => `${fs || 16}px`};
  }
`;

const EmailAddress = () => {
  const isSmallScreen = useSmallScreen();
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { toggle, Dialog } = useAttentionDialog("~~ Oh.", "Tips");
  return (
    <>
      {Dialog()}
      <GridFlex container>
        <GridFlex container item xs={12} top={40} justify="center">
          <GridFlex item xs={isSmallScreen ? 8 : 5}>
            <MuiThemeProvider theme={primaryTheme}>
              <StyledOutlinedInput
                fullWidth
                fs={16}
                height={50}
                right={10}
                color="primary"
                id="outlined-secondary"
                placeholder="Enter your email address"
                inputRef={inputRef}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton>
                //       <ArrowRightAltIcon width={24} height={24} color="primary" />
                //     </IconButton>
                //   </InputAdornment>
                // }
              />
            </MuiThemeProvider>
          </GridFlex>
          <GridFlex item xs={isSmallScreen ? 4 : 2}>
            <StyledButton
              fullWidth
              fs={isSmallScreen ? 14 : 16}
              variant="contained"
              text="SUBSCRIBE"
              onClick={async () => {
                const email = inputRef?.current.value;
                if (!isEmailAddress(email)) {
                  return toggle("Incorrect Emaill Address.");
                }
                try {
                  const result = await saveEmail({ email });
                  if (result.data.code === 1) {
                    return toggle("Sign Up Success !");
                  }
                  throw new Error(`Sign Up Error: ${result.data.data.message}`);
                } catch (e: any) {
                  return toggle(e.message);
                }
              }}
            />
          </GridFlex>
        </GridFlex>
      </GridFlex>
    </>
  );
};

export const SignUp = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <StyledGridFlex
      container
      padding={isSmallScreen ? "130px 0 0 0" : "300px 0 255px 0"}
      direction="column"
      justify="center"
    >
      <BoxBase>
        <Title fs={isSmallScreen ? 30 : 48} align="center">
          FOLLOW US
        </Title>
      </BoxBase>
      {/* <BoxBase>
        <Title align="center">YOUR INBOX</Title>
      </BoxBase> */}
      <BoxBase margin="45px 0 0 0">
        <SubTitle fs={isSmallScreen ? 16 : 16} align="center">
          Get the latest news and development updates directly to your inbox
        </SubTitle>
      </BoxBase>
      <EmailAddress />
    </StyledGridFlex>
  );
};
