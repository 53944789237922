import { memo } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import RouteWithSubRoutes from "./RouteWithSubRoutes";

import { CustomRouteItem } from "../types";

const RenderRoutes = ({ routes = [], parentPath = "" }: CustomRouteItem): JSX.Element => {
  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <RouteWithSubRoutes
            exact={route.exact || false}
            key={i}
            {...route}
            path={`${parentPath}${route.path}`}
          />
        );
      })}
      <Route component={NotFound} />
    </Switch>
  );
};

export default memo(RenderRoutes, () => true);
