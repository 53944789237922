import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";

export const AvatarBase = styled(Avatar)<{ width?: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  cursor: pointer;
`;

export const AvatarLogo = styled(AvatarBase)`
  border: none;
  img {
    object-fit: contain;
  }
`;
