import styled from "styled-components";
import { IconButton, Typography, Container } from "@material-ui/core";

import { GridFlex } from "../../components/Styles/Grid";
import { AvatarLogo } from "../../components/Styles/Avatar";

import Twitter from "../../images/svg/twitter.svg";
import Telegram from "../../images/svg/TELe.svg";
import Github from "../../images/svg/github.svg";
import Discord from "../../images/svg/discord.svg";
import BackgroundFour from "../../images/voxels/bg04.png";
import { ExternalLink } from "../../components/ResponseLink";
import { useSmallScreen } from "../../hooks/useSmallScreen";

const StyledGridFlex = styled(GridFlex)<{ background?: string }>`
  position: relative;
  background-color: ${({ background }) => `${background !== "false" ? "#000818" : "#1c1d1f"}`};
  background-repeat: no-repeat;
  background-image: ${({ background }) =>
    background === "true" ? `url(${BackgroundFour})` : "inherit"};
  background-position: bottom center;
  background-size: contain;
`;

const StyledBottom = styled(GridFlex)`
  position: absolute;
  bottom: 0;
  padding-top: 22px;
  padding-bottom: 22px;
  border-top: 1px solid #4a4a4a;
`;

const StyledTypography = styled(Typography)<{ fs?: number | undefined }>`
  color: #8e8e8e;
  font-size: ${({ fs }) => `${fs || 24}px`};
`;

export const Bottom = ({ background = false }: { background?: boolean }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <StyledGridFlex container background={background.toString()} padding="160px 0 140px 0">
      <GridFlex container justify="center">
        <IconButton
          onClick={() => {
            window.open("https://twitter.com/KanamitNFT");
          }}
        >
          <AvatarLogo width={48} height={48} src={Twitter} />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open("https://t.me/kanamitgroup");
          }}
        >
          <AvatarLogo width={48} height={48} src={Telegram} />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open("https://github.com/token-tech/kanamit");
          }}
        >
          <AvatarLogo width={48} height={48} src={Github} />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open("https://discord.gg/gMPTeJT9bk");
          }}
        >
          <AvatarLogo width={48} height={48} src={Discord} />
        </IconButton>
      </GridFlex>
      <StyledBottom container>
        <Container>
          <GridFlex container>
            <GridFlex
              container
              item
              xs={6}
              justify="flex-start"
              direction={isSmallScreen ? "column" : "row"}
            >
              <StyledTypography fs={12}>2021 Kanamit</StyledTypography>
              {!isSmallScreen && <StyledTypography fs={12}>|</StyledTypography>}
              <StyledTypography fs={12}>All Rights Reserved.</StyledTypography>
            </GridFlex>
            <GridFlex
              container
              item
              xs={6}
              justify="flex-end"
              alignItems="flex-end"
              direction={isSmallScreen ? "column" : "row"}
            >
              <ExternalLink isLink url="/user/tou">
                <StyledTypography fs={12}>Terms of Use</StyledTypography>
              </ExternalLink>
              {!isSmallScreen && <StyledTypography fs={12}>&nbsp;|&nbsp;</StyledTypography>}
              <ExternalLink isLink url="/user/pp">
                <StyledTypography fs={12}>Privacy Policy</StyledTypography>
              </ExternalLink>
            </GridFlex>
          </GridFlex>
        </Container>
      </StyledBottom>
    </StyledGridFlex>
  );
};
