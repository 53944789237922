import styled from "styled-components";
import Card from "@material-ui/core/Card";

export const CardBase = styled(Card)<{
  maxwidth: number;
  top?: number;
  bottom?: number;
  padding?: string;
  theme?: "light" | "dark";
}>`
  @media (min-width: 1280px) {
    max-width: ${(props) => `${props.maxwidth}px`};
  }
  width: 100%;
  margin-top: ${(props) => `${props.top || 0}px`};
  margin-bottom: ${({ bottom }) => `${bottom || 0}px`};
  padding: ${(props) => `${props.padding || 0}`};
  box-sizing: border-box;
  background-color: ${({ theme }) => `${theme === "dark" ? "#242527" : "inherit"}`};
`;
