import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import useModel from "flooks";
import { useEffect } from "react";

import { getMintedVideoIds } from "../../api/server";
import { Minted } from "../../models/User";

const useMineMintedVideosIds = () => {
  const { minted, update } = useModel(Minted);
  const { account: address } = useWeb3React<Web3Provider>();
  useEffect(() => {
    if (!address || minted) {
      return;
    }
    getMintedVideoIds({ address }).then((res) => {
      if (!res.data.data.items) {
        return;
      }
      const mintedIds = res.data.data.items.map((item: any) => {
        return item.videoId;
      });
      update(mintedIds);
    });
  }, [address, minted, update]);
  return minted;
};

export default useMineMintedVideosIds;
