import styled from "styled-components";
import { Container } from "@material-ui/core";
import { Box, Grid, Typography } from "@material-ui/core";

import { Title, SubTitle } from "../Styles";
import { GridFlex } from "../../../components/Styles/Grid";

import { useSmallScreen } from "../../../hooks/useSmallScreen";

import BashOne from "../../../images/voxels/bash1@2x.png";
import BashTwo from "../../../images/voxels/bash2@2x.png";
import Safe from "../../../images/voxels/safe@2x.png";
import Modular from "../../../images/voxels/modular@2x.png";
import OpenSource from "../../../images/voxels/open_source@2x.png";

const TextBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
`;

const SubTitleBox = styled(TextBox)`
  padding: 70px 5% 0 5%;
`;

const FeatureItem = styled(GridFlex)`
  padding: 30px 60px;
  border-radius: 8px;
  border: 1px solid #4a4a4a;
`;

const FeatureBox = styled(Box)`
  margin-top: 15px;
`;
const FeatureImg = styled.img`
  width: 72px;
  height: 72px;
`;

const DescriptionBox = styled(Box)`
  margin-top: 30px;
  color: #a4a4a4;
`;
const DescriptionText = styled(Typography)<{ fs?: number }>`
  font-size: ${({ fs }) => `${fs || 16}px`};
`;
const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const Feature = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <Container>
      <GridFlex container>
        <GridFlex item xs={isSmallScreen ? 12 : 6} padding={isSmallScreen ? "auto" : "0 10px 0 0"}>
          <Box>
            <Img src={BashOne} />
          </Box>
        </GridFlex>
        <GridFlex
          item
          xs={isSmallScreen ? 12 : 6}
          top={isSmallScreen ? 20 : 0}
          padding={isSmallScreen ? "auto" : "0 0 0 10px"}
        >
          <Box>
            <Img src={BashTwo} />
          </Box>
        </GridFlex>
      </GridFlex>
      <GridFlex padding={`0 0 ${isSmallScreen ? "50px " : "100px"} 0`}>
        <SubTitleBox>
          <SubTitle fs={isSmallScreen ? 18 : 22}>
            Reduce the risk of vulnerabilities in your applications by using standard, tested,
            community-reviewed code.
          </SubTitle>
        </SubTitleBox>
      </GridFlex>
      <Grid container spacing={3}>
        <Grid item xs={isSmallScreen ? 12 : 4}>
          <FeatureItem container direction="column">
            <FeatureBox>
              <FeatureImg src={Safe} />
            </FeatureBox>
            <FeatureBox>
              <Title fs={isSmallScreen ? 14 : 16}>Focused on security</Title>
            </FeatureBox>
            <DescriptionBox>
              <DescriptionText fs={isSmallScreen ? 14 : 16}>
                Using top level standard contracts security patterns and best practices.
              </DescriptionText>
            </DescriptionBox>
          </FeatureItem>
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 4}>
          <FeatureItem container direction="column">
            <FeatureBox>
              <FeatureImg src={Modular} />
            </FeatureBox>
            <FeatureBox>
              <Title fs={isSmallScreen ? 14 : 16}>Modular approach</Title>
            </FeatureBox>
            <DescriptionBox>
              <DescriptionText fs={isSmallScreen ? 14 : 16}>
                Simple, robust code. Easy collaboration and auditing
              </DescriptionText>
            </DescriptionBox>
          </FeatureItem>
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 4}>
          <FeatureItem container direction="column">
            <FeatureBox>
              <FeatureImg src={OpenSource} />
            </FeatureBox>
            <FeatureBox>
              <Title fs={isSmallScreen ? 14 : 16}>Open Source</Title>
            </FeatureBox>
            <DescriptionBox>
              <DescriptionText fs={isSmallScreen ? 14 : 16}>
                Community-driven. Used by the biggest players in the industry.
              </DescriptionText>
            </DescriptionBox>
          </FeatureItem>
        </Grid>
      </Grid>
    </Container>
  );
};
