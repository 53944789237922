import { BrowserRouter as Router } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./utils/ethers/getLibrary";
import Routes from "./routes";
import "./App.css";

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router basename="/">
        <Routes />
      </Router>
    </Web3ReactProvider>
  );
}

export default App;
