import { GridFlex } from "../../components/Styles/Grid";

export const TabPanel = ({
  children,
  value,
  index,
}: {
  children: JSX.Element;
  value: number;
  index: number;
}) => {
  return <>{value === index && <GridFlex container>{children}</GridFlex>}</>;
};
