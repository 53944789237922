import styled from "styled-components";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

import { SecondaryButton } from "../../components/Styles/Button";

const StyledDialogContent = styled(DialogContent)`
  padding-bottom: 30px;
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 30px 0;
    color: #ffffff;
    background: #1c1f24;
  }
`;

const StyledTitle = styled(DialogTitle)`
  text-align: center;
`;

const StyledActions = styled(DialogActions)`
  justify-content: center;
`;

export interface DialogActionsStruct {
  disagree?: { text?: string; onClick?: any };
  agree?: { text?: string; onClick?: any };
}

export const DialogBase = ({
  open = false,
  actions = null,
  title = "Dialog Title",
  body = null,
  onClose,
}: {
  open?: boolean;
  actions?: DialogActionsStruct | null;
  title?: string;
  body?: JSX.Element | null;
  onClose?: any;
}) => {
  return (
    <StyledDialog fullWidth open={open} onClose={onClose}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDialogContent>{body}</StyledDialogContent>
      {actions && (
        <StyledActions>
          {actions.disagree && (
            <SecondaryButton
              variant="outlined"
              onClick={actions.disagree?.onClick}
              text={(actions && actions.disagree?.text) || "Disagree"}
            />
          )}
          {actions.agree && (
            <SecondaryButton
              size="large"
              variant="contained"
              onClick={actions.agree?.onClick}
              text={(actions && actions?.agree?.text) || "Agree"}
            />
          )}
        </StyledActions>
      )}
    </StyledDialog>
  );
};
