import styled from "styled-components";
import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import { Title, SubTitle } from "../Styles";
import { useSmallScreen } from "../../../hooks/useSmallScreen";
import { GridFlex } from "../../../components/Styles/Grid";
import { SecondaryButton } from "../../../components/Styles/Button";

import FrameworkBackground from "../../../images/voxels/bg-ide@2x.png";

const StyledContainer = styled(Container)<{ bs?: string }>`
  padding-top: 65px;
  padding-bottom: 100px;
  background-image: url(${FrameworkBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ bs }) => `${bs || "contain"}`};
  background-color: #02081b;
`;

const TextBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
`;

const TitleBox = styled(TextBox)`
  padding: 0 10%;
`;

const SubTitleBox = styled(TextBox)`
  padding: 0 5%;
`;

const StyledButton = styled(SecondaryButton)`
  padding: 10px 65px;
`;

export const Introduction = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <StyledContainer disableGutters maxWidth={false}>
      <Container>
        <GridFlex top={isSmallScreen ? 0 : 100}>
          <TitleBox>
            <Title fs={isSmallScreen ? 32 : 48}>
              SIMPLE STEPS TO CREATE A FULLY FUNCTIONAL NFT STORE
            </Title>
          </TitleBox>
        </GridFlex>
        <GridFlex top={isSmallScreen ? 20 : 40}>
          <SubTitleBox>
            <SubTitle fs={isSmallScreen ? 18 : 22}>
              Open-interface is a completely free NFT store creation tool, by
              simple configuration and provide few APIs, then you can make yourself a fully
              functional NFT store. 
            </SubTitle>
          </SubTitleBox>
        </GridFlex>
        <GridFlex container top={isSmallScreen ? 20 : 40} justify="center">
          <StyledButton
            variant="contained"
            text="GET STARTED"
            onClick={() => {
              window.location.href = "http://docs.kanamit.io/docs/development/interface";
            }}
          />
        </GridFlex>
      </Container>
    </StyledContainer>
  );
};
