import { Typography } from "@material-ui/core";
import { useState, useCallback } from "react";
import { DialogBase, DialogActionsStruct } from "../../components/Dialog";

export const useAttentionDialog = (defaultText?: string, title?: string) => {
  const [text, setText] = useState<string | null>(null);
  const [newTitle, setNewTitle] = useState<string | null>(null);
  function toggle(currentText?: string, newTitle?: string) {
    if (currentText) {
      setText(currentText);
    }
    if (newTitle) {
      setNewTitle(newTitle);
    }
    toggleDialog();
  }
  const { toggle: toggleDialog, Dialog } = useDialog({
    title: newTitle || title || "Tips",
    body: <Typography align="center">{text || defaultText || "attention text"}</Typography>,
    actions: {
      agree: {
        text: "Confirm",
        onClick: () => {
          toggle();
        },
      },
    },
  });
  return { toggle, Dialog };
};

export const useDialog = ({
  title,
  body = null,
  actions = null,
  onClose,
}: {
  title?: string;
  body?: JSX.Element | null;
  actions?: DialogActionsStruct | null;
  onClose?: any;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const Dialog = useCallback(() => {
    const onDialogClose = () => {
      typeof onClose === "function" && onClose();
      setIsOpen(false);
    };
    return (
      <DialogBase
        open={isOpen}
        title={title}
        body={body}
        actions={actions}
        onClose={onDialogClose}
      />
    );
  }, [isOpen, title, body, onClose, actions]);

  return {
    isOpen,
    toggle,
    Dialog,
  };
};
