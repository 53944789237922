import ChannelAvatarOne from "../../images/voxels/statics/channels4_profile.jpeg";
import ThumbnailOne from "../../images/voxels/statics/hqdefault.jpeg";

import ChannelAvatarTwo from "../../images/voxels/statics/unnamed.jpeg";
// import ThumbnailTwo from "../../images/voxels/statics/hqdefault2.jpeg";

import ThumbnailThree from "../../images/voxels/statics/hqdefault3.jpeg";

// import ChannelAvatarFour from "../../images/voxels/statics/unnamed4.jpeg";
// import ThumbnailFour from "../../images/voxels/statics/hqdefault4.jpeg";

import ThumbnailFive from "../../images/voxels/statics/hqdefault5.jpeg";

// import ChannelAvatarSix from "../../images/voxels/statics/channels6_profile.jpeg";
// import ThumbnailSix from "../../images/voxels/statics/hqdefault6.jpeg";

export const StaticVideos = [
  {
    address: "0xFB22B65cF71A45d47389dDf1F2491C238B371586",
    bids: [],
    channelAvatar: ChannelAvatarOne,
    channelId: "UCG-S875veiYBEpDr9-BkepA",
    channelTitle: "Mia-Zhou",
    description: "",
    id: 52,
    mintedAt: "2021-06-16T12:16:48.000Z",
    publishedAt: "2021-06-16T04:31:39Z",
    reservePrice: "0",
    sign: "0xbfba4b554c8acf52b8d8ff2c7e59b51b8156ea9fd523843013d5f638935e75a03d56a1513ee9c863e286eec75949327a5b998aea1960679333e724de311072731b",
    status: 1,
    subscriberCount: "0",
    updatedAt: "2021-06-16T12:16:48.000Z",
    videoId: "9qc8MZO2raQ",
    videoThumbnail: ThumbnailOne,
    videoTitle: "Pexels-Videos-1204911",
    viewCount: "0",
  },
  // {
  //   address: "0x582e7f4DCfA0cd1d7F438f68Cb01971B8F82068A",
  //   bids: [],
  //   channelAvatar: ChannelAvatarTwo,
  //   channelId: "UCmbGQqCB9tjUTXdti35wwVg",
  //   channelTitle: "Angela-NFT",
  //   description: "Video-by-Florin-Chivu-from-Pixabay",
  //   id: 51,
  //   mintedAt: "2021-06-16T12:15:25.000Z",
  //   publishedAt: "2021-06-07T06:56:19Z",
  //   reservePrice: "0",
  //   sign: "0xaa03864f822975d83cf7132d7e71e635e76bcaf7777b425456eb75d4ebd0979b7784963d37f45c3bcffd77090dbcc72f49c7561c26c2fe4c9b7473a8da00a0b41b",
  //   status: 1,
  //   subscriberCount: "1",
  //   updatedAt: "2021-06-16T12:15:25.000Z",
  //   videoId: "ttTsTOTaMLs",
  //   videoThumbnail: ThumbnailTwo,
  //   videoTitle: "Sharing-your-life-on-NFT-world",
  //   viewCount: "1",
  // },
  {
    address: "0x582e7f4DCfA0cd1d7F438f68Cb01971B8F82068A",
    bids: [],
    channelAvatar: ChannelAvatarTwo,
    channelId: "UCmbGQqCB9tjUTXdti35wwVg",
    channelTitle: "Angela-NFT",
    description: "",
    id: 48,
    mintedAt: "2021-06-16T12:14:59.000Z",
    publishedAt: "2021-06-16T12:08:26Z",
    reservePrice: "0",
    sign: "0xc90792bf7627068f63e6060c2e5c7cc381a9eaab4f462979b7cb52c5f153150a41c46a4cbe8cc4245a0d5ca643fe5629643bdd07c8fe49fd6a5ec9f082ef77f61c",
    status: 1,
    subscriberCount: "1",
    updatedAt: "2021-06-16T12:14:59.000Z",
    videoId: "FbPjnAL4iwI",
    videoThumbnail: ThumbnailThree,
    videoTitle: "Anthony-Skraba-production-NFT",
    viewCount: "0",
  },
  // {
  //   address: "0x582e7f4DCfA0cd1d7F438f68Cb01971B8F82068A",
  //   bids: [],
  //   channelAvatar: ChannelAvatarFour,
  //   channelId: "UCRYd32HRG5tOHb6e4nAaS0g",
  //   channelTitle: "Aacharya",
  //   description: "",
  //   id: 35,
  //   mintedAt: "2021-06-16T11:48:20.000Z",
  //   publishedAt: "2021-06-16T11:38:58Z",
  //   reservePrice: "0",
  //   sign: "0x9c0088643c15e53535e64246d992290f0c7e6ba70bc9fe710cbfa7ab3243ab90081f69e0e590fb8467819a37bd5d37c4b48b042cd0b548e22f6311b4663c60cf1c",
  //   status: 1,
  //   subscriberCount: "0",
  //   updatedAt: "2021-06-16T11:48:20.000Z",
  //   videoId: "6LUHKtySakM",
  //   videoThumbnail: ThumbnailFour,
  //   videoTitle: "pexels-shvets-production-8020667",
  //   viewCount: "0",
  // },
  {
    address: "0xFB22B65cF71A45d47389dDf1F2491C238B371586",
    bids: [],
    channelAvatar: ChannelAvatarOne,
    channelId: "UCG-S875veiYBEpDr9-BkepA",
    channelTitle: "Mia-Zhou",
    description: "",
    id: 42,
    mintedAt: "2021-06-16T11:49:10.000Z",
    publishedAt: "2021-06-16T05:55:16Z",
    reservePrice: "0",
    sign: "0x9f711fd635dd9f210d23c7148cc954181d96507f457b6c99de4dc810a7c11d30621786fb8df5acc12778ca97f75cfaa664d5da472aa27e5971c524000e0498ed1c",
    status: 1,
    subscriberCount: "0",
    updatedAt: "2021-06-16T11:49:10.000Z",
    videoId: "sjFUnh5xbBY",
    videoThumbnail: ThumbnailFive,
    videoTitle: "Pexels-Videos-1456685",
    viewCount: "0",
  },
  // {
  //   address: "0xD440915C9621804052C861EA0e51e27fFc97Dc8E",
  //   bids: [],
  //   channelAvatar: ChannelAvatarSix,
  //   channelId: "UChiZRPDOEaaLrAVdV3uuMdw",
  //   channelTitle: "forest-populus",
  //   description: "",
  //   id: 25,
  //   mintedAt: "2021-06-16T05:49:59.000Z",
  //   publishedAt: "2021-06-16T03:48:37Z",
  //   reservePrice: "500",
  //   sign: "0x1f4dbc5901e64609563d32d58691590734fced724e3c6eb0465c944ef0416ffc27a9a6b2da4d9c157e16c6d22057ba13a0a65fc17d3f710ff087582b884845151b",
  //   status: 1,
  //   subscriberCount: "0",
  //   updatedAt: "2021-06-16T05:49:59.000Z",
  //   videoId: "r4MVL6VoDh4",
  //   videoThumbnail: ThumbnailSix,
  //   videoTitle: "pexels-koolshooters-7325243",
  //   viewCount: "0",
  // },
];
