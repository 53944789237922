import styled from "styled-components";
import { Tabs, Tab, Grid } from "@material-ui/core";

import { useSmallScreen } from "../../hooks/useSmallScreen";

const StyledTabs = styled(Tabs)<{ padding?: string | undefined; mt?: number }>`
  max-width: 100%;
  margin-top: ${({ mt }) => `${mt || 105}px`};
  & .MuiTab-root {
    padding: ${({ padding }) => `${padding ? padding : "5px 50px"}`};
    margin-left: 30px;
  }
  & .Mui-selected {
    color: #9b58ff;
    border-bottom: 1px solid #9b58ff;
  }
  & .MuiSvgIcon-root {
    color: #ffffff;
    stroke: #ffffff;
  }
  & .MuiTabs-indicator {
    display: none;
    background-color: none;
  }
`;

const StyledTab = styled(Tab)`
  font-size: 18px;
  color: #d4d4d4;
`;

export const VoxelsTabs = ({
  labs,
  value,
  padding,
  onChange,
}: {
  labs: string[];
  value: number;
  onChange: any;
  padding?: string;
}) => {
  const isSmallScreen = useSmallScreen();
  const handleChange = (_: any, newValue: number) => {
    onChange(newValue);
  };
  return (
    <Grid container justify="center">
      <StyledTabs
        value={value}
        padding={padding}
        mt={isSmallScreen ? 75 : 105}
        onChange={handleChange}
        scrollButtons="on"
        variant="scrollable"
        aria-label="voxel-table"
      >
        {labs.map((lab, i) => {
          return <StyledTab key={`${lab}-${i}`} label={lab} />;
        })}
      </StyledTabs>
    </Grid>
  );
};
