import { useState } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

import { VoxelsTabs } from "./VoxelsTabs";
import { Title, StyledGridFlex } from "./Styles";
import { TabPanel } from "../../components/TabPanel";
import { BoxBase } from "../../components/Styles/Box";
import { GridFlex } from "../../components/Styles/Grid";
import { AvatarLogo } from "../../components/Styles/Avatar";
// import { WihteButton } from "../../components/Styles/Button";
// import { useAttentionDialog } from "../../hooks/Component/useDialog";
import StarTwo from "../../images/voxels/star2.png";
import StageIconBg from "../../images/voxels/stage_icon_bg.png";

import { AllItems, ItemInterface } from "./StageItems";
// import { ExternalLink } from "../../components/ResponseLink";
import { useSmallScreen } from "../../hooks/useSmallScreen";

const StageGridFlex = styled(StyledGridFlex)`
  /* background-size: contain;
  background-image: url(${StarTwo});
  background-repeat: no-repeat;
  background-position: top right; */
`;

const SpecieItemGrid = styled(GridFlex)`
  padding: 40px 0;
  flex-direction: column;
  border-bottom: 1px solid #4a4a4a;
`;

const AlignCenterGrid = styled(GridFlex)`
  align-items: center; ;
`;

const StageIconGrid = styled(AlignCenterGrid)<{ bs?: string }>`
  background-image: url(${StageIconBg});
  background-repeat: no-repeat;
  background-size: ${({ bs }) => `${bs || "50%"}`};
  background-position: center;
`;

// const ButtonGrid = styled(AlignCenterGrid)`
//   border-bottom: 1px solid #4a4a4a;
// `;

const ItemTitle = styled(Typography)<{ size: number }>`
  width: 100%;
  font-size: ${({ size }) => size}px;
  text-align: left;
`;

const ItemDescription = styled(Typography)<{ size: number }>`
  font-size: ${({ size }) => size}px;
  text-align: left;
`;

// const StyledButton = styled(WihteButton)<{ fs?: number }>`
//   font-size: ${({ fs }) => `${fs || 18}px`};
// `;

const GridContainer = styled(GridFlex)`
  cursor: pointer;
`;

const StyledBox = styled(BoxBase)`
  display: flex;
  width: 100%;
  justify-content: flex-start; ;
`;

const Item = ({ item }: { item: ItemInterface }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <GridFlex item xs={isSmallScreen ? 12 : 6}>
      <GridContainer
        container
        onClick={() => {
          if (item.url) {
            window.open(item.url);
          }
        }}
      >
        <StageIconGrid
          container
          item
          xs={isSmallScreen ? 3 : 4}
          bs={isSmallScreen ? "70%" : "40%"}
          justify="center"
        >
          {/* <ImageIcon fontSize={isSmallScreen ? "small" : "large"} htmlColor="#FFFFFF" /> */}
          <AvatarLogo
            width={isSmallScreen ? 40 : 80}
            height={isSmallScreen ? 40 : 80}
            src={item.logo}
          />
        </StageIconGrid>
        <SpecieItemGrid container item xs={isSmallScreen ? 9 : 8} justify="flex-start">
          <StyledBox component="div" color="#FFFFFF">
            <ItemTitle size={isSmallScreen ? 16 : 24}>{item.title}</ItemTitle>
          </StyledBox>
          <StyledBox component="div" color="#A4A4A4" height="50px">
            <ItemDescription size={isSmallScreen ? 12 : 16}>{item.description}</ItemDescription>
          </StyledBox>
        </SpecieItemGrid>
        {/* <ButtonGrid container item xs={isSmallScreen ? 4 : 3} justify="flex-end">
          <ExternalLink url={item.url}>
            <StyledButton
              fs={isSmallScreen ? 12 : 18}
              variant="outlined"
              text={item.url ? "View Details" : "Coming soon"}
            />
          </ExternalLink>
        </ButtonGrid> */}
      </GridContainer>
    </GridFlex>
  );
};

const Items = ({ items }: { items: ItemInterface[] }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <GridFlex container top={isSmallScreen ? 30 : 120}>
      {items.map((item, i) => {
        return <Item item={item} key={i} />;
      })}
    </GridFlex>
  );
};

const StageTabPanel = ({
  value,
  index,
  items,
}: {
  value: number;
  index: number;
  items: ItemInterface[];
}) => {
  return (
    <TabPanel value={value} index={index}>
      <Items items={items} />
    </TabPanel>
  );
};

export const Stage = () => {
  const handleChange = (value: number) => {
    setTabValue(value);
  };
  const panels = AllItems;
  const [tabValue, setTabValue] = useState<number>(1);
  const isSmallScreen = useSmallScreen();
  // const { Dialog, toggle } = useAttentionDialog("Comming Soon !", "Oh ~~~");
  return (
    <StageGridFlex pt={isSmallScreen ? 125 : 250} align="center">
      {/* {Dialog()} */}
      <Title fs={isSmallScreen ? 32 : 48}>STAGE</Title>
      <VoxelsTabs
        padding="0 50px"
        value={tabValue}
        onChange={handleChange}
        labs={["Species", "Genus", "Family", "Order", "Class", "Phylum", "Kingdom", "Domain"]}
      />
      {panels.map((items, i) => {
        return <StageTabPanel key={i} items={items} value={tabValue} index={i} />;
      })}
    </StageGridFlex>
  );
};
