import { Box } from "@material-ui/core";
import styled from "styled-components";

export const BoxBase = styled(Box)<{
  margin?: string;
  padding?: string;
  height?: string;
  color?: string;
}>`
  max-width: 100%;
  height: ${({ height }) => `${height || "auto"}`};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "auto"};
  color: ${({ color }) => `${color || "inherit"}`};
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
`;
