import { CustomRouteItem } from "../types";

import { Kana } from "../pages/NFTVOXELS/Kana";
import { OpenInterface } from "../pages/NFTVOXELS/OpenInterface";
import { NFTVOXELS } from "../pages/NFTVOXELS";
import { Terms } from "../pages/NFTVOXELS/User/Terms";
import { Policy } from "../pages/NFTVOXELS/User/Policy";

import RenderRoutes from "../components/RenderRoutes";

// import { KanaTest } from "../pages/Test/Kana";

const base: CustomRouteItem[] = [
  {
    exact: true,
    path: "/",
    component: NFTVOXELS,
  },
  // {
  //   exact: true,
  //   path: "/kana",
  //   component: Kana,
  // },
  {
    exact: true,
    path: "/openInterface",
    component: OpenInterface,
  },
  {
    exact: true,
    path: "/user/tou",
    component: Terms,
  },

  {
    exact: true,
    path: "/user/pp",
    component: Policy,
  },
  // {
  //   exact: true,
  //   path: "/test",
  //   component: KanaTest,
  // },
];

const Routes = (): JSX.Element => {
  return <RenderRoutes routes={base} />;
};

export default Routes;
