import request from "axios";
import { API_PREFIX } from "../../constants";

const CONTROLLER = "email";

export const saveEmail = (data: { email: string }) => {
  return request({
    url: `${API_PREFIX}/${CONTROLLER}/save`,
    method: "POST",
    data,
  });
};
