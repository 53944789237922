import { useEffect } from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

import Header from "./Header";
import { CanDo } from "./CanDo";
import { Stage } from "./Stage";
import { Slogan } from "./Slogan";
import { Framework } from "./Framework";
// import { MegaFi } from "./MegaFi";
// import { ClickBuy } from "./ClickBuy";
import { SocialMedia } from "./SocialMedia";
import { SignUp } from "./SignUp";
// import { Dao } from "./Dao";
import { Bottom } from "./Bottom";
// import { KanaEconomics } from "./KanaEconomics";

// import { GridFlex } from "../../components/Styles/Grid";
// import { useSmallScreen } from "../../hooks/useSmallScreen";

const StyledContainer = styled(Container)`
  background: #000818;
`;

// const StyledGrid = styled(GridFlex)`
//   max-width: 1440px;
// `;

export const NFTVOXELS = function (): JSX.Element {
  useEffect(() => {
    document.querySelector("#googleAPI")?.remove();
  }, []);
  return (
    <Container disableGutters maxWidth={false}>
      <Header showMenu background="#1C1D1F" />
      <StyledContainer maxWidth={false}>
        <Container>
          <Slogan />
          <Framework />
          {/* <MegaFi /> */}
          <SocialMedia />
          {/* <KanaEconomics /> */}
          <CanDo />
          <Stage />
          {/* <Dao /> */}
          <SignUp />
        </Container>
      </StyledContainer>
      <Bottom background />
    </Container>
  );
};
