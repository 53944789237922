import React, { useRef, MutableRefObject, ForwardedRef } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { useState, MouseEvent } from "react";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";

// import { ExternalLink } from "../../components/ResponseLink";
import { GridFlex } from "../../components/Styles/Grid";
import { ButtonBase } from "../../components/Styles/Button";
import { useSmallScreen } from "../../hooks/useSmallScreen";

const DOCS = "DOCS";
const PRODUCTS = "PRODUCTS";

const CusMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenu = styled(CusMenu)`
  & .MuiMenu-paper {
    border: none;
    background-color: #1c1d1f;
  }
  & .MuiMenuItem-root,
  & a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    padding-top: 7.5;
    padding-bottom: 7.5;
    line-height: 22px;
    opacity: 1;
    :not(:first-child) {
      margin-top: 10px;
    }
    :hover {
      color: #9b58ff;
    }
  }
  & .MuiListItem-gutters {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  fill: #ffffff;
  stroke: #ffffff;
`;

const StyledButton = styled(ButtonBase)`
  & :hover {
    color: #9b58ff;
  }
`;

const MobileMenuItems = ({ handleClose }: { handleClose: any }) => {
  return (
    <>
      <MenuItem
        onClick={() => {
          window.location.href =
            "https://v1.alchemy.do/dao/0xbd925cc17680fbcaef3bf172ad139db5090efba8";
          handleClose();
        }}
      >
        Kanamit DAO
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.location.href = "http://docs.kanamit.io/docs/intro";
          handleClose();
        }}
      >
        Docs
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.location.href = "https://github.com/token-tech/kanamit";
          handleClose();
        }}
      >
        Github
      </MenuItem>
    </>
  );
};

export const ProductionMenuList = ({
  anchorEl,
  onMouseMove,
  handleClose,
}: {
  anchorEl: null | HTMLElement;
  onMouseMove?: any;
  handleClose: any;
}) => {
  const history = useHistory();
  const isSmallScreen = useSmallScreen();
  return (
    <StyledMenu
      id="production-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      TransitionComponent={Fade}
      onMouseMove={onMouseMove}
    >
      <MenuItem
        onClick={() => {
          window.open("https://vv.kanamit.io");
          handleClose();
        }}
      >
        Youtube NFT Demo
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push("/openInterface");
          handleClose();
        }}
      >
        Contracts
      </MenuItem>
      {isSmallScreen && <MobileMenuItems handleClose={handleClose} />}
    </StyledMenu>
  );
};

export const DocsMenuList = ({
  anchorEl,
  onMouseMove,
  handleClose,
}: {
  anchorEl: null | HTMLElement;
  onMouseMove?: any;
  handleClose: any;
}) => {
  const menus = [
    { name: "Introduction", url: "http://docs.kanamit.io/docs/intro" },
    { name: "Development", url: "http://docs.kanamit.io/docs/development/interface" },
    { name: "FAQ", url: "http://docs.kanamit.io/docs/faq/interface" },
  ];
  return (
    <StyledMenu
      id="docs-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      onMouseMove={onMouseMove}
      TransitionComponent={Fade}
    >
      {menus.map((item, i) => {
        return (
          <MenuItem
            key={i}
            onClick={() => {
              window.location.href = item.url;
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </StyledMenu>
  );
};

const FoldNavMenuItem = ({
  anchorEl,
  handleClose,
}: // handleClick,
{
  handleClick?: any;
  handleClose: any;
  anchorEl: null | HTMLElement;
}) => {
  return <ProductionMenuList anchorEl={anchorEl} handleClose={handleClose} />;
};

export const FoldNavMenu = ({
  anchorEl,
  handleClick,
  handleClose,
}: {
  anchorEl: null | HTMLElement;
  setAnchorEl: any;
  handleClick: any;
  handleClose: any;
}) => {
  return (
    <GridFlex container justify="flex-end">
      <IconButton aria-controls="nav-menu" aria-haspopup="true" onClick={handleClick}>
        <StyledMenuIcon />
      </IconButton>
      <FoldNavMenuItem anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} />
    </GridFlex>
  );
};

interface NavMenuItemPropsType {
  name: string;
  type?: string;
  url?: string;
  handleClick: any;
  handleClose: any;
  onMouseMove?: any;
  anchorEl?: null | HTMLElement;
  SubMenu?: any;
}

const NavMenuItem = React.forwardRef(
  (props: NavMenuItemPropsType, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      name,
      url,
      type,
      handleClick,
      onMouseMove,
      SubMenu,
      handleClose,
      anchorEl = null,
    } = props;
    return (
      <GridFlex left={40}>
        {url && (
          <StyledButton
            fontcolor="#FFFFFF"
            onClick={() => {
              window.location.href = url;
            }}
          >
            {name}
          </StyledButton>
        )}
        {SubMenu && (
          <StyledButton
            ref={ref}
            fontcolor="#FFFFFF"
            aria-controls={`${name}-menu`}
            aria-haspopup="true"
            onMouseEnter={(e: any) => {
              handleClick && handleClick(e, type);
            }}
          >
            {name}
          </StyledButton>
        )}
        {SubMenu && (
          <SubMenu
            onMouseMove={(e: any) => {
              onMouseMove(e, type);
            }}
            handleClose={(e: any) => {
              handleClose(e, type);
            }}
            anchorEl={anchorEl || null}
          />
        )}
      </GridFlex>
    );
  }
);

const isMouseOnMenuItem = (
  e: MouseEvent<HTMLButtonElement>,
  type: string,
  productRef: MutableRefObject<HTMLButtonElement>,
  docsRef: MutableRefObject<HTMLButtonElement>
): boolean => {
  if (!e || !productRef.current || !docsRef.current) {
    return false;
  }
  const { clientX, clientY } = e;
  const {
    current: {
      offsetTop: productTop = 0,
      offsetLeft: productLeft = 0,
      offsetHeight: productHeight = 0,
      offsetWidth: productWidth = 0,
    },
  } = productRef;
  const {
    current: {
      offsetTop: docsTop = 0,
      offsetLeft: docsLeft = 0,
      offsetHeight: docsHeight = 0,
      offsetWidth: docsWidth = 0,
    },
  } = docsRef;
  if (type === PRODUCTS) {
    if (
      clientX > productLeft &&
      clientX < productLeft + productWidth &&
      clientY > productTop &&
      clientY < productTop + productHeight
    ) {
      return true;
    }
    return false;
  }
  if (type === DOCS) {
    if (
      clientX > docsLeft &&
      clientX < docsLeft + docsWidth &&
      clientY > docsTop &&
      clientY < docsTop + docsHeight
    ) {
      return true;
    }
    return false;
  }
  return false;
};

const isMouseOnSubMenu = (
  e: MouseEvent<HTMLButtonElement>,
  type: string,
  productRef: MutableRefObject<HTMLButtonElement>,
  docsRef: MutableRefObject<HTMLButtonElement>
): boolean => {
  if (!e || !productRef.current || !docsRef.current) {
    return false;
  }
  const { clientX, clientY } = e;
  const {
    current: {
      offsetTop: productTop = 0,
      offsetLeft: productLeft = 0,
      offsetHeight: productHeight = 0,
      offsetWidth: productWidth = 0,
    },
  } = productRef;
  const {
    current: {
      offsetTop: docsTop = 0,
      offsetLeft: docsLeft = 0,
      offsetHeight: docsHeight = 0,
      offsetWidth: docsWidth = 0,
    },
  } = docsRef;
  if (type === PRODUCTS) {
    if (
      clientX > productLeft &&
      clientX < productLeft + productWidth &&
      clientY > productTop &&
      clientY < productTop + productHeight
    ) {
      return true;
    }
    if (
      clientX > productLeft &&
      clientX < productLeft + 180 &&
      clientY > 55 &&
      clientY < productTop + 150
    ) {
      return true;
    }
    return false;
  }
  if (type === DOCS) {
    if (
      clientX > docsLeft &&
      clientX < docsLeft + docsWidth &&
      clientY > docsTop &&
      clientY < docsTop + docsHeight
    ) {
      return true;
    }
    if (clientX > docsLeft && clientX < docsLeft + 136 && clientY > 55 && clientY < docsTop + 190) {
      return true;
    }
    return false;
  }
  return false;
};

export const NavMenu = () => {
  const isSmallScreen = useSmallScreen();
  const timeoutRef = useRef<any>();
  const docsRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const productRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const [docsAnchorEl, setDocsAnchorEl] = useState<null | HTMLElement>(null);
  const [productAnchorEl, setProductAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>, type: string) => {
    if (type === DOCS) {
      setDocsAnchorEl(e.currentTarget);
    } else if (type === PRODUCTS) {
      setProductAnchorEl(e.currentTarget);
    }
  };

  const handleClose = (e: MouseEvent<HTMLButtonElement>, type: string) => {
    const mousePosition = isMouseOnMenuItem(e, type, productRef, docsRef);
    if (!mousePosition) {
      if (type === PRODUCTS) {
        return setProductAnchorEl(null);
      } else if (type === DOCS) {
        return setDocsAnchorEl(null);
      }
    }
  };

  const onMouseMove = (e: MouseEvent<HTMLButtonElement>, type: string) => {
    if (timeoutRef) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const mousePosition = isMouseOnSubMenu(e, type, productRef, docsRef);
      if (!mousePosition) {
        if (type === PRODUCTS) {
          setProductAnchorEl(null);
        } else if (type === DOCS) {
          setDocsAnchorEl(null);
        }
      }
      clearTimeout(timeoutRef.current);
    }, 100);
  };
  const menus = [
    {
      name: "Products",
      type: PRODUCTS,
      ref: productRef,
      anchorEl: productAnchorEl,
      SubMenu: ProductionMenuList,
    },
    {
      name: "Kanamit DAO",
      url: "https://v1.alchemy.do/dao/0xbd925cc17680fbcaef3bf172ad139db5090efba8",
    },
    { name: "Docs", type: DOCS, ref: docsRef, anchorEl: docsAnchorEl, SubMenu: DocsMenuList },
    { name: "Github", url: "https://github.com/token-tech/kanamit" },
  ];
  if (isSmallScreen) {
    return (
      <FoldNavMenu
        anchorEl={productAnchorEl}
        setAnchorEl={setProductAnchorEl}
        handleClick={(e: MouseEvent<HTMLButtonElement>) => {
          handleClick(e, PRODUCTS);
        }}
        handleClose={(e: MouseEvent<HTMLButtonElement>) => {
          handleClose(e, PRODUCTS);
        }}
      />
    );
  }
  return (
    <GridFlex container>
      {menus.map((item, i) => {
        const { ref, type, url, name, SubMenu, anchorEl } = item;
        return (
          <NavMenuItem
            key={i}
            ref={ref}
            url={url}
            name={name}
            type={type}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            onMouseMove={onMouseMove}
            SubMenu={SubMenu}
          />
        );
      })}
    </GridFlex>
  );
};
