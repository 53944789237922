import { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
// import { format } from "timeago.js";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import { IconETH } from "../Icon";
import { BoxBase } from "../Styles/Box";
import { VideoData } from "../../types";
import { CardBase } from "../Styles/Card";
import { GridFlex } from "../Styles/Grid";
import { ButtonBase } from "../Styles/Button";
import { useKanamitPageQRcode } from "../../hooks/useQRcode";

dayjs.extend(updateLocale);
dayjs.extend(LocalizedFormat);

dayjs.updateLocale("en", {
  monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
});

const StyledCardMedia = styled(CardMedia)`
  padding: 15px;
  /* padding-top: 56.25%; // 16:9 */
`;

const StyledBox = styled(BoxBase)<{
  theme?: "light" | "dark";
  color?: string | undefined;
  justify?: "flex-start" | "flex-end" | "center" | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => `${justify || "flex-start"}`};
  color: ${({ theme, color }) => `${theme === "dark" ? "#FFFFFF" : color || "#585758"}`};
`;

const Username = styled(Typography)`
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
`;

const TypograhpyETH = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
`;

const VideoTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  height: 60px;
  overflow: hidden;
`;

const DetailsText = styled(Typography)`
  font-size: 12px;
  line-height: 17px;
`;

const MintedDate = styled(Typography)`
  height: 17px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
`;

const QRcodeImg = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 5px;
  border: 1px solid #7e8182;
`;

const ActiveText = styled(Typography)`
  height: 22px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 800;
`;

const IFrameWrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
  border: 10px solid #e8e1e8;
`;

const IFrameContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  width: 300%; /* enlarge beyond browser width */
  left: -100%; /* center */
`;

const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledImg = styled(CardMedia)`
  height: 0;
  padding-top: 56.25%; // 16:9;
`;

const StyledButton = styled(ButtonBase)``;

const VideoIframe = ({
  videoId,
  title,
  thumbnail,
}: {
  videoId?: string;
  title?: string;
  thumbnail?: string | undefined;
}) => {
  if (!videoId) {
    return null;
  }
  if (thumbnail) {
    return <StyledImg image={thumbnail} title={title || ""} />;
  }
  return (
    <IFrameWrapper>
      <IFrameContainer>
        <StyledIFrame
          width="100%"
          height="auto"
          src={`https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1`}
          title={title || ""}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IFrameContainer>
    </IFrameWrapper>
  );
};

const VideoCard = ({
  video,
  top = 20,
  noWrap = true,
  maxwidth = 600,
  isMinted = false,
  theme = "light",
  handleAction,
  thumbnail,
}: {
  top?: number;
  video: VideoData;
  noWrap?: boolean;
  maxwidth?: number;
  isMinted?: boolean;
  theme?: "light" | "dark";
  handleAction?: any;
  thumbnail?: boolean | undefined;
}): JSX.Element | null => {
  const bid = (video?.bids && video?.bids[0]) || null;
  const [QRcode, setQRcode] = useState<string | null>();
  useKanamitPageQRcode(video.videoId).then((qrcode: string | null) => {
    setQRcode(qrcode);
  });

  if (!video) {
    return null;
  }
  return (
    <CardBase top={top} maxwidth={maxwidth} theme={theme}>
      <StyledCardMedia title={video.videoTitle || ""}>
        <VideoIframe
          thumbnail={thumbnail ? video.videoThumbnail || "" : undefined}
          videoId={video.videoId || ""}
          title={video.videoTitle || ""}
        />
      </StyledCardMedia>
      <CardContent>
        <GridFlex container>
          <GridFlex item xs={6}>
            <Avatar alt={video.channelTitle || ""} src={video.channelAvatar || ""} />
            <StyledBox theme={theme}>
              <Username>{video.channelTitle}</Username>
            </StyledBox>
          </GridFlex>
          <GridFlex item xs={6} container justify="flex-end">
            {bid && (
              <StyledBox theme={theme}>
                <IconETH width={22} height={22} />
              </StyledBox>
            )}
            <StyledBox color="#1A2E35" theme={theme}>
              <TypograhpyETH>{bid?.eth || ""}</TypograhpyETH>
            </StyledBox>
          </GridFlex>
        </GridFlex>
        <GridFlex container top={20}>
          <GridFlex container item>
            <GridFlex item xs={8}>
              <StyledBox color="#1A2E35" theme={theme}>
                <VideoTitle>{video.videoTitle}</VideoTitle>
              </StyledBox>
            </GridFlex>
            <GridFlex item xs={4} container alignItems="flex-end" direction="column">
              <BoxBase>
                {(isMinted || handleAction) && (
                  <StyledButton
                    disabled={isMinted}
                    variant={isMinted ? "outlined" : "contained"}
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("mint video ....");
                      handleAction && handleAction(video);
                    }}
                  >
                    {isMinted ? "MINTED" : "MINT"}
                  </StyledButton>
                )}
              </BoxBase>
              <StyledBox color="#1A2E35" theme={theme} justify="flex-end">
                <MintedDate>{isMinted ? dayjs(video.mintedAt).format("YY.MM.DD") : " "}</MintedDate>
              </StyledBox>
            </GridFlex>
          </GridFlex>
          <GridFlex container item>
            <GridFlex container item xs={8} direction="column">
              <StyledBox color="#7E8182" theme={theme}>
                <DetailsText>Subscriber：{video.subscriberCount}</DetailsText>
              </StyledBox>
              <StyledBox color="#7E8182" theme={theme}>
                <DetailsText>YouTube View：{video.viewCount}</DetailsText>
              </StyledBox>
              <StyledBox color="#7E8182" theme={theme}>
                <DetailsText>Created date：{dayjs(video.publishedAt).format("LL")}</DetailsText>
              </StyledBox>
            </GridFlex>
            <GridFlex container item xs={4} direction="column">
              <StyledBox justify="flex-end">
                <QRcodeImg src={QRcode || ""} />
              </StyledBox>
            </GridFlex>
            <GridFlex container item xs={12} top={25} direction="row">
              <StyledBox color="#1A2E35" theme={theme}>
                <ActiveText>{bid ? `@${bid.username}` : ""}</ActiveText>
              </StyledBox>
              <StyledBox color="#585758" theme={theme}>
                <ActiveText>{bid ? "Offered" : ""}</ActiveText>
              </StyledBox>
              <StyledBox color="#1A2E35" theme={theme}>
                <ActiveText>{bid ? `$ ${bid.price}` : ""}</ActiveText>
              </StyledBox>
            </GridFlex>
          </GridFlex>
        </GridFlex>
      </CardContent>
    </CardBase>
  );
};

export default VideoCard;
