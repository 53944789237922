import request from "axios";
import { API_PREFIX } from "../../constants";
import { VideoData } from "../../types";

const CONTROLLER = "nonFungbleToken";
export interface MintVideoToServerParams {
  sign: string;
  address: string;
  video: VideoData;
}

export const updateVideoAddress = (data: { id: number; address: string }) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/updateAddress`,
    method: "POST",
    data,
  });
};

export const mintVideoToServer = (data: MintVideoToServerParams) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/mint`,
    method: "POST",
    data,
  });
};

export const updateVideoReservePrice = (data: { id: number; price: number }) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/updateReservePrice`,
    method: "POST",
    data,
  });
};

export const getMintedVideoIds = (params: { address: string }) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/minted`,
    method: "GET",
    params,
  });
};

export const getVideoDetail = (params: { videoId: string }) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/getVideoDetail`,
    method: "GET",
    params,
  });
};

export const getVideoDetailList = (params: { offset?: number; limit?: number }) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/listWithBid`,
    // url: API_PREFIX + `/${CONTROLLER}/list`,
    method: "GET",
    params,
  });
};

export const addVideoToServer = (data: { video: VideoData }) => {
  return request({
    url: API_PREFIX + `/${CONTROLLER}/add`,
    method: "POST",
    data,
  });
};
