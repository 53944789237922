import { useState, useEffect } from "react";
import styled from "styled-components";

import List from "./List";
import { SecondaryButton } from "../Styles/Button";

import { VideoData } from "../../types";
import { useSmallScreen } from "../../hooks/useSmallScreen";

const StyledButton = styled(SecondaryButton)<{ fs?: number }>`
  /* height: 65px; */
  font-size: ${({ fs }) => `${fs || 24}px`};
`;

export const StaticList = ({
  loadSize = false,
  videos,
  theme = "dark",
  thumbnail,
}: {
  loadSize?: boolean | number | undefined;
  theme?: "dark" | "light" | undefined;
  videos: VideoData[];
  thumbnail?: boolean | undefined;
}) => {
  const isSmallScreen = useSmallScreen();
  const [isLoadAll, setIsLoadAll] = useState<boolean>(true);
  const [showVideos, setShowVideos] = useState<VideoData[] | null>(videos);
  useEffect(() => {
    const sv =
      !isLoadAll && loadSize && videos?.length > loadSize
        ? videos.slice(0, parseInt(loadSize.toString()) || 0)
        : videos;
    setShowVideos(sv);
  }, [isLoadAll, loadSize, videos]);

  return (
    <>
      <List theme={theme} justVideo thumbnail={thumbnail} videos={showVideos} offer isPublic />
      {!isLoadAll && loadSize && videos?.length > loadSize && (
        <StyledButton
          fullWidth
          fs={isSmallScreen ? 16 : 24}
          text="AND MORE"
          variant="contained"
          onClick={() => {
            setIsLoadAll(true);
          }}
        />
      )}
    </>
  );
};
