import Icon_1 from "../../images/svg/stage/786.svg";
import Icon_2 from "../../images/svg/stage/787.svg";
import Icon_3 from "../../images/svg/stage/788.svg";
import Icon_4 from "../../images/svg/stage/789.svg";
import Icon_5 from "../../images/svg/stage/803.svg";
import Icon_6 from "../../images/svg/stage/813.svg";
import Icon_7 from "../../images/svg/stage/852.svg";
import Icon_8 from "../../images/svg/stage/853.svg";
import Icon_9 from "../../images/svg/stage/854.svg";
import Icon_10 from "../../images/svg/stage/856.svg";
import Icon_11 from "../../images/svg/stage/857.svg";
import Icon_12 from "../../images/svg/stage/858.svg";

export interface ItemInterface {
  url?: string;
  logo: string;
  title: string;
  description?: string;
}

export const SpeciesItems = [
  {
    url: "https://kanamit.io",
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_1,
    description: "",
  },
];

export const GenusItems = [
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_2,
    description: "",
  },
  {
    title: "The Trade Protocol",
    logo: Icon_3,
    description: "MegaFi, adds liquidity for NFT through dealer and DeFi lending tools.",
  },
];

export const FamilyItems = [
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_4,
    description: "",
  },
  {
    title: "The Trade Protocol",
    logo: Icon_5,
    description: "MegaFi",
  },
  {
    title: "The Token Standard Protocol",
    logo: Icon_6,
    description: "Wrap Protocol",
  },
];

export const OrderItems = [
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_7,
    description: "",
  },
  { title: "The Application Protocol", logo: Icon_8, description: "Interface SDK" },
  {
    title: "The Finance Protocol",
    logo: Icon_9,
    description: "Combine protocol, Lending, Trade, Fragmentation operation.",
  },
  { title: "The Function Protocol", logo: Icon_10, description: "Interact protocol layer." },
  {
    title: "THE Non-Fungible Token Standard",
    logo: Icon_3,
    description: "NFT721, NFT1155 NFT998.....",
  },
  {
    title: "Public Chain Contract Layers",
    logo: Icon_1,
    description: "",
  },
];

export const ClassItems = [
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_11,
    description: "",
  },
  { title: "The Application Protocol", logo: Icon_12, description: "NFT Smart Pool protocol" },
  {
    title: "The Finance Protocol",
    logo: Icon_1,
    description: "Multiply 10 Protocol, collections of algorithms",
  },
  {
    title: "The Function Protocol",
    logo: Icon_2,
    description: "Add functions to NFT",
  },
];

export const PhylumItems = [
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_4,
    description: "",
  },
  {
    title: "The Application Protocol",
    logo: Icon_5,
    description: "NFT Lending protocol",
  },
  {
    title: "The Maketing protocol",
    logo: Icon_7,
    description: "Mystery Box protocol",
  },
  {
    title: "The Finance Protocol",
    logo: Icon_8,
    description: "Combine protocol",
  },
  {
    title: "The Function Protocol",
    logo: Icon_9,
    description: "Interoperate data port",
  },
  {
    title: "The Chain and the Smart Contract Protocol",
    logo: Icon_3,
    description: "IPFS, SDK",
  },
];

const KingdomItems = [
  {
    title: "The Kanamit Protocol",
    logo: Icon_10,
    description: "Framework Framework",
  },
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_11,
    description: "",
  },
  {
    title: "The Application Protocol",
    logo: Icon_12,
    description: "NFT asset synthesization protocol",
  },
  {
    title: "The Creative Commons Protocol",
    logo: Icon_1,
    description: "CC protocols",
  },
  {
    title: "The Finance Protocol",
    logo: Icon_2,
    description: "",
  },
  {
    title: "The Function Protocol",
    logo: Icon_3,
    description: "Metaverse",
  },
  {
    title: "The Chain and the Smart Contract Protocol",
    logo: Icon_4,
    description: "Cross-platform bridger, Ethereum Layer 2 SDK, support asset cross chains.",
  },
];

export const DomainItems = [
  {
    title: "The Kanamit Protocol Framework Framework",
    logo: Icon_5,
    description: "Iteration of Multiverse framework",
  },
  {
    title: "Web2.0 NFT Framework Tool",
    logo: Icon_6,
    description: "",
  },
  {
    title: "The Application Protocol",
    logo: Icon_7,
    description: "",
  },
  { title: "The Creative Commons Protocol", logo: Icon_8, description: "" },
  { title: "The Finance Protocol", logo: Icon_9, description: "" },
  {
    title: "The Chain and the Smart Contract Protocol",
    logo: Icon_10,
    description:
      "Smart contract and public chain layer, deal with store, calculate, consensus of Multiverse chain protocol.",
  },
];

export const AllItems = [
  SpeciesItems,
  GenusItems,
  FamilyItems,
  OrderItems,
  ClassItems,
  PhylumItems,
  KingdomItems,
  DomainItems,
];
