import { useState } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

import { VoxelsTabs } from "./VoxelsTabs";
import { Title, StyledGridFlex } from "./Styles";
import { TabPanel } from "../../components/TabPanel";

import { StaticList } from "../../components/VideoCard/StaticList";

import { useSmallScreen } from "../../hooks/useSmallScreen";
import { useAttentionDialog } from "../../hooks/Component/useDialog";

import { StaticVideos } from "./StaticVideos";

const TitleBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const YoutubeTabPanel = ({ value, index }: { value: number; index: number }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <TabPanel value={value} index={index}>
      <StaticList videos={StaticVideos} thumbnail loadSize={isSmallScreen ? 3 : false} />
    </TabPanel>
  );
};

export const SocialMedia = () => {
  const { Dialog, toggle } = useAttentionDialog("we are comming soon", "STAY TUNED");
  const [tabValue, setTabValue] = useState<number>(0);
  const isSmallScreen = useSmallScreen();
  const handleChange = (value: any) => {
    if (value !== 0) {
      // if (value === 1) {
      //   return window.open("https://v.cent.co");
      // }
      return toggle("we are comming soon");
    }
    setTabValue(value);
  };
  return (
    <StyledGridFlex container pt={isSmallScreen ? 150 : 250} justify="center" alignItems="center">
      {Dialog()}
      <TitleBox>
        <Title fs={isSmallScreen ? 32 : 48}>SOCIAL MEDIA NFT TOOLS</Title>
      </TitleBox>
      <VoxelsTabs
        // labs={["Youtube", "Twitter", "Medium", "WordPress", "Weibo"]}
        labs={["Youtube", "WordPress"]}
        value={tabValue}
        onChange={handleChange}
      />
      <YoutubeTabPanel value={tabValue} index={0} />
    </StyledGridFlex>
  );
};
