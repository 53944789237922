import { useEffect } from "react";
import styled from "styled-components";
import { Container } from "@material-ui/core";

import Header from "../Header";
import { Bottom } from "../Bottom";

import { Introduction } from "./Introduction";
import { Feature } from "./Feature";

const StyledContainer = styled(Container)`
  padding-bottom: 120px;
  background-color: #02081b;
`;

export const OpenInterface = function (): JSX.Element {
  useEffect(() => {
    document.querySelector("#googleAPI")?.remove();
  }, []);
  return (
    <Container disableGutters maxWidth={false}>
      <Header showMenu background="#1C1D1F" />
      <Introduction />
      <StyledContainer disableGutters maxWidth={false}>
        <Feature />
      </StyledContainer>
      <Bottom />
    </Container>
  );
};
