import { memo } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import { CustomRouteComponentProps } from "../types";

const RouteWithSubRoutes = (props: CustomRouteComponentProps) => {
  const { routes, path, component: RouteComponent } = props;
  return (
    <Route
      path={path}
      render={(subProps): JSX.Element => (
        // pass the sub-routes down to keep nesting
        <RouteComponent {...subProps} routes={routes} />
      )}
    />
  );
};

// 使用装饰器(withRouter) 可以在非路由组props中拿到history、location对象
export default withRouter(memo(RouteWithSubRoutes, () => true));
