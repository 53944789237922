import produce from "immer";
export interface GoogleUserInfo {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
}

export const User = (now: any) => ({
  userInfo: null,
  update(info: GoogleUserInfo | null) {
    const { userInfo } = now();
    const nextUserInfo = produce(userInfo, () => {
      return info;
    });
    now({ userInfo: nextUserInfo });
  },
});

export interface Video extends gapi.client.youtube.Video {
  channel?: gapi.client.youtube.Channel;
}

export const Videos = (now: any) => ({
  videos: null,
  update(userVideos: null | Video[]) {
    const { videos } = now();
    const nextVideos = produce(videos, () => {
      return userVideos;
    });
    now({ videos: nextVideos });
  },
});

export interface UserToken {
  access_token: string;
  expires_at: number;
  expires_in: number;
  first_issued_at: number;
  id_token: string;
  idpId: string;
  login_hint: string;
  scope: string;
  session_state: any;
  token_type: string;
}

export const Token = (now: any) => ({
  token: null,
  update(userToken: UserToken | null) {
    const { token } = now();
    const nextToken = produce(token, () => {
      return userToken;
    });
    now({ token: nextToken });
  },
});

export const Minted = (now: any) => ({
  minted: null,
  update(userMinted: string[] | null) {
    const { minted } = now();
    const nextMinted = produce(minted, () => {
      return userMinted;
    });
    now({ minted: nextMinted });
  },
  push(mintedId: string | null | undefined) {
    if (!mintedId) {
      return;
    }
    const { minted } = now();
    const nextMinted = produce(minted, (draft: any) => {
      if (!minted) {
        return [mintedId];
      }
      if (minted) {
        draft.push(mintedId);
      }
    });
    now({ minted: nextMinted });
  },
});
