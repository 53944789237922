import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { Title, StyledGridFlex } from "./Styles";
import { useSmallScreen } from "../../hooks/useSmallScreen";
import { GridFlex } from "../../components/Styles/Grid";
import FrameworkIMG from "../../images/voxels/ide@2x.png";
import { SecondaryButton } from "../../components/Styles/Button";
import { useHistory } from "react-router";

const TitleBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  padding-right: 15px;
  color: #ffffff;
`;

const Text = styled(Typography)<{ fs?: number }>`
  padding-left: 15px;
  font-size: ${({ fs }) => `${fs || 16}px`};
`;

const StyledButton = styled(SecondaryButton)<{ padding?: string }>`
  padding: ${(padding) => `${padding || "15px 110px"}`};
`;

export const Framework = () => {
  const history = useHistory();
  const isSmallScreen = useSmallScreen();
  return (
    <StyledGridFlex container pt={isSmallScreen ? 150 : 250} justify="center" alignItems="center">
      <TitleBox>
        <Title fs={isSmallScreen ? 32 : 48}>KANAMIT NFT Framework INTRODUCTION</Title>
      </TitleBox>
      <GridFlex container top={110}>
        <GridFlex item xs={isSmallScreen ? 12 : 6}>
          <Box>
            <Img src={FrameworkIMG} />
          </Box>
        </GridFlex>
        <GridFlex container item xs={isSmallScreen ? 12 : 6} direction="column" justify="center">
          <GridFlex container>
            <StyledBox>
              <CheckCircleIcon />
              <Text variant="body2">Web 2.0 NFT Store</Text>
            </StyledBox>
          </GridFlex>
          <GridFlex container top={15}>
            <StyledBox>
              <CheckCircleIcon />
              <Text variant="body2">A Free system for connecting NFT from different platforms</Text>
            </StyledBox>
          </GridFlex>
          <GridFlex container top={15}>
            <StyledBox>
              <CheckCircleIcon />
              <Text variant="body2">One-stop tool, make text, image, video to NFT easily</Text>
            </StyledBox>
          </GridFlex>
          <GridFlex container top={isSmallScreen ? 20 : 80}>
            <StyledButton
              padding={isSmallScreen ? "10px 80px" : "15px 110px"}
              variant="contained"
              text="MORE"
              fs={isSmallScreen ? 14 : 16}
              endIcon={<ArrowRightAltIcon fontSize="large" />}
              onClick={() => {
                history.push("/openInterface");
              }}
            />
          </GridFlex>
        </GridFlex>
      </GridFlex>
    </StyledGridFlex>
  );
};
