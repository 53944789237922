import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { ExternalLink } from "../ResponseLink";
import VideoCard from "./index";
import { VideoData } from "../../types";
import useMineMintedVideosIds from "../../hooks/Server/useMinted";
import { useSmallScreen } from "../../hooks/useSmallScreen";

const ListContainer = styled(Grid)`
  margin-top: 40px;
  margin-bottom: 60px;
`;

const VideoPaper = ({
  isMinted,
  videoData,
  handleAction,
  library,
  offer = false,
  theme = "light",
  justVideo = false,
  thumbnail,
}: {
  offer: boolean;
  isMinted: boolean;
  handleAction?: any;
  videoData: VideoData;
  theme?: "light" | "dark";
  justVideo?: boolean;
  library: Web3Provider | undefined;
  thumbnail?: boolean | undefined;
}) => {
  // if (justVideo) {
  //   return (
  //     <VideoCard isMinted={isMinted} theme={theme} handleAction={handleAction} video={videoData} />
  //   );
  // }
  // // const bid = videoData.bids && videoData?.bids[0];
  return (
    <VideoCard
      isMinted={isMinted}
      thumbnail={thumbnail}
      theme={theme}
      handleAction={handleAction}
      video={videoData}
    />
  );
};

const List = ({
  videos,
  handleAction,
  isPublic,
  offer = false,
  title = "",
  theme = "light",
  justVideo = false,
  thumbnail,
}: {
  videos: VideoData[] | null;
  handleAction?: any;
  isPublic?: boolean;
  offer?: boolean;
  title?: string | undefined;
  theme?: "dark" | "light" | undefined;
  justVideo?: boolean;
  thumbnail?: boolean | undefined;
}) => {
  const isSmallScreen = useSmallScreen();
  const { library } = useWeb3React<Web3Provider>();
  const mineMintedVideoIds = useMineMintedVideosIds() || [];

  return (
    <>
      {title && videos && <Typography variant="h4">{title}</Typography>}
      <ListContainer container spacing={3}>
        {videos &&
          videos.map((video: VideoData, i: number) => {
            const isMinted = isPublic
              ? true
              : handleAction
              ? mineMintedVideoIds.includes(video.videoId)
              : false;
            return (
              <Grid key={`youtube-container-${i}`} item xs={isSmallScreen ? 12 : 4}>
                <ExternalLink
                  isLink={isMinted}
                  url={`https://vv.kanamit.io/video/${video.videoId}`}
                >
                  <VideoPaper
                    theme={theme}
                    offer={offer}
                    isMinted={isMinted}
                    videoData={video}
                    library={library}
                    handleAction={handleAction}
                    justVideo={justVideo}
                    thumbnail={thumbnail}
                  />
                </ExternalLink>
              </Grid>
            );
          })}
      </ListContainer>
    </>
  );
};

export default List;
