import { ComponentsProps } from "@material-ui/core/styles/props";
import SvgIcon from "@material-ui/core/SvgIcon";
import styled from "styled-components";

export const ETHSvg = (props: ComponentsProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M-1820.809-1401.783l6.37,10.586-6.37,3.787-6.37-3.787Zm0,20.847,6.37-9.006-6.37,3.787-6.37-3.787Zm0,0"
        transform="translate(1827.179 1401.783)"
      />
    </SvgIcon>
  );
};

export const IconETH = styled(ETHSvg)<{ width?: number; height?: number }>`
  width: ${({ width }) => `${width || 16}px`};
  height: ${({ height }) => `${height || 16}px`};
`;
