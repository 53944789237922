import styled from "styled-components";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";

import { GridFlex } from "../../components/Styles/Grid";
import { AvatarLogo } from "../../components/Styles/Avatar";

import { NavMenu } from "./NavMenu";

import Logo from "../../images/voxels/logo_index.png";
import { Typography } from "@material-ui/core";
import { useSmallScreen } from "../../hooks/useSmallScreen";
import { NORMAL_SCREEN_PADDING_LEFT, SMALL_SCREEN_PADDING_LEFT } from "../../constants";
import { APP_NAME } from "./constants";

const HeaderRoot = styled.div`
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)<{
  padding?: string | undefined;
  background?: string | undefined;
}>`
  & .MuiToolbar-gutters {
    padding: ${({ padding }) => `${padding || 0}`};
  }
  background-color: ${({ background }) => `${background || "rgba(0,0,0,0)"}}`};
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  width: 94px;
  height: 55px;
  margin-left: 15px;
  font-size: 16px;
  /* font-family: PingFang SC; */
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
  opacity: 1;
`;

const Header = ({
  showMenu,
  background,
}: {
  showMenu?: boolean;
  background?: string;
}): JSX.Element => {
  const isSmallScreen = useSmallScreen();
  return (
    <HeaderRoot>
      <StyledAppBar
        position="static"
        color="primary"
        background={background}
        padding={`0 0 0 ${
          isSmallScreen ? SMALL_SCREEN_PADDING_LEFT : NORMAL_SCREEN_PADDING_LEFT
        }px`}
      >
        <Toolbar>
          <Container disableGutters>
            <GridFlex container justify="center">
              <GridFlex item xs={isSmallScreen ? 10 : 2}>
                <Link to="/">
                  <GridFlex>
                    <AvatarLogo width={55} height={55} variant="square" src={Logo} />
                    <StyledTypography>{APP_NAME}</StyledTypography>
                  </GridFlex>
                </Link>
              </GridFlex>
              <GridFlex item xs={isSmallScreen ? 2 : 10}>
                {showMenu ? <NavMenu /> : null}
              </GridFlex>
            </GridFlex>
          </Container>
        </Toolbar>
      </StyledAppBar>
    </HeaderRoot>
  );
};

export default Header;
