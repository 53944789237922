import styled from "styled-components";
import Container from "@material-ui/core/Container";
import { Typography, Box } from "@material-ui/core";

export const Link = styled.a`
  color: #9b58ff;
`;

export const StyledContainer = styled(Container)`
  padding: 55px 0;
  background: #000818;
`;

export const TextBox = styled(Box)`
  margin-top: 25px;
  color: #ffffff;
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
`;

export const SubTitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
`;

export const Text = styled(Typography)`
  font-size: 14px;
`;

export const WarningText = styled(Typography)`
  font-size: 18px;
`;
