import styled from "styled-components";
import { Typography } from "@material-ui/core";

import { Title } from "./Styles";
import { BoxBase } from "../../components/Styles/Box";
import { GridFlex } from "../../components/Styles/Grid";
import { AvatarLogo } from "../../components/Styles/Avatar";

import DepthIcon from "../../images/voxels/depth@2x.png";
import VarietyIcon from "../../images/voxels/variety@2x.png";
import MetaverseIcon from "../../images/voxels/metaverse@2x.png";
import ElasticityIcon from "../../images/voxels/elasticity@2x.png";
// import DocumentIcon from "../../images/svg/document.svg";
import { useSmallScreen } from "../../hooks/useSmallScreen";

interface DataInterface {
  description?: string;
  icon: string;
}

const Datas: DataInterface[] = [
  {
    description: "Elasticity NFT protocol Framework structure, to adapt to the ever-changing NFTs",
    icon: ElasticityIcon,
  },
  { description: "Increase NFT kinds, make NFT more operability", icon: MetaverseIcon },
  { description: "Add liquidity for NFT and increase the market depth", icon: DepthIcon },
  { description: "To be the base of NFT voxels in different metaverses", icon: VarietyIcon },
];

const StyledTypography = styled(Typography)<{ fs?: number }>`
  color: #ffffff;
  font-size: ${({ fs }) => `${fs || 24}px`};
  font-weight: 400;
  line-height: 33px;
  /* font-family: PingFang SC; */
`;

const StyledBox = styled(BoxBase)`
  text-align: center;
`;

const Item = ({ data }: { data: DataInterface }) => {
  const isSmallScreen = useSmallScreen();
  return (
    <GridFlex item top={30} xs={isSmallScreen ? 6 : 3}>
      <GridFlex container direction="column">
        <GridFlex container justify="center">
          <AvatarLogo
            width={isSmallScreen ? 50 : 100}
            height={isSmallScreen ? 50 : 100}
            src={data.icon}
          />
        </GridFlex>
        <StyledBox margin="40px 0 0 0">
          <StyledTypography fs={isSmallScreen ? 14 : 16}>{data.description}</StyledTypography>
        </StyledBox>
      </GridFlex>
    </GridFlex>
  );
};

const List = () => {
  return (
    <GridFlex container top={100}>
      {Datas.map((data, i) => {
        return <Item key={i} data={data} />;
      })}
    </GridFlex>
  );
};

export const CanDo = () => {
  const isSmallScreen = useSmallScreen();
  return (
    <GridFlex container top={isSmallScreen ? 145 : 265} direction="column" alignItems="center">
      <Title fs={isSmallScreen ? 32 : 48} align="center">
        WHAT CAN KANAMIT DO
      </Title>
      <GridFlex item xs={12}>
        <List />
      </GridFlex>
    </GridFlex>
  );
};
