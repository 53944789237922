import styled from "styled-components";
import { Button, ButtonProps } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const primaryTheme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: { primary: { main: "#FFFFFF" } },
});

const secondaryTheme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: { primary: { main: "#9B58FF" } },
});

interface ThemeButtonProps extends ButtonProps {
  text: string;
  fs?: number;
  height?: number;
  padding?: string;
}

export const WihteButton = (props: ThemeButtonProps) => {
  const { text, ...others } = props;
  return (
    <MuiThemeProvider theme={primaryTheme}>
      <ButtonBase color="primary" {...others}>
        {text}
      </ButtonBase>
    </MuiThemeProvider>
  );
};

export const SecondaryButton = (props: ThemeButtonProps) => {
  const { text, ...others } = props;
  return (
    <MuiThemeProvider theme={secondaryTheme}>
      <ButtonBase color="primary" {...others}>
        {text}
      </ButtonBase>
    </MuiThemeProvider>
  );
};

export const ButtonBase = styled(Button)<{
  width?: number;
  height?: number;
  right?: number;
  left?: number;
  padding?: string;
  grow?: number;
  fs?: number;
  fontcolor?: string;
}>`
  width: ${({ width }) => (width ? `${width}px` : "")};
  height: ${({ height }) => (height ? `${height}px` : "")};
  margin-right: ${({ right }) => `${right || 0}px`};
  margin-left: ${({ left }) => `${left || 0}px`};
  padding: ${({ padding }) => `${padding || "auto"}`};
  flex-grow: ${({ grow }) => grow || "inherit"};
  color: ${({ fontcolor }) => fontcolor || ""};
  font-size: ${({ fs }) => (fs ? `${fs}px` : "inherit")};
`;
